import React from "react";
import { EmailIntegrationCard } from "./email-integration-card.component";

export const EmailIntegrationCards = () => {
  return (
    <>
      <EmailIntegrationCard provider="gmail" />
      <EmailIntegrationCard provider="outlook" />
      <EmailIntegrationCard provider="other" />
    </>
  );
};

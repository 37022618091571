import React, { useCallback, useEffect, useMemo, useState } from "react";
import { capitalize } from "lodash";
import {
  CpButton,
  CpIcon,
  CpModal,
  CpSelectSingle,
  CpWell,
  phoneNumber,
} from "canopy-styleguide!sofe";

type SelectContactModalProps = {
  show: boolean;
  onClose: () => void;
  onSave: (contact: any) => void;
  client: any;
  currentContact: any;
  availableContacts: any[];
};

type ContactDropdownData = {
  id: string;
  name: string;
  subName?: string;
  is_available: boolean;
  disabled?: boolean;
};

type ContactDropdownGroupData = {
  id: string;
  name: string;
  icon: string;
  showCount: boolean;
  data: ContactDropdownData[];
};

export const SelectContactModal = ({
  show,
  onClose,
  onSave,
  client,
  currentContact,
  availableContacts,
}: SelectContactModalProps) => {
  const [selectedContact, setSelectedContact] = useState(currentContact);
  const [selectedContactData, setSelectedContactData] = useState<any | null>();

  const updateSelectedContact = useCallback(
    (contact: any) => {
      if (!contact) {
        setSelectedContact(null);
        setSelectedContactData(null);
        return;
      }
      setSelectedContact(contact);
      const contactData = client?.contacts.find(
        (c: any) => c.id === contact.id,
      );
      const email =
        contactData.emails?.find((e: any) => e.is_primary)?.value ||
        contactData.emails[0]?.value;
      const phone =
        contactData.phones?.find((p: any) => p.is_primary)?.value ||
        contactData.phones[0]?.value;
      const primaryAddress =
        contactData.addresses?.find((a: any) => a.is_primary)?.value ||
        contactData.addresses[0]?.value;
      const address = {
        address_1: primaryAddress?.address_1 || "",
        address_2: primaryAddress?.address_2 || "",
        locality: primaryAddress?.locality || "",
        region: primaryAddress?.region || "",
        postal_code: primaryAddress?.postal_code || "",
      };
      const hasAddress =
        address.address_1 ||
        address.address_2 ||
        address.locality ||
        address.region ||
        address.postal_code;
      setSelectedContactData({
        email,
        phone,
        address: hasAddress ? address : null,
      });
    },
    [client?.contacts],
  );

  useEffect(() => {
    updateSelectedContact(currentContact);
  }, [updateSelectedContact, currentContact]);

  const data = useMemo(() => {
    const groupData: ContactDropdownGroupData[] = [
      {
        id: "available",
        name: "Available (Not currently synced)",
        icon: "person",
        showCount: false,
        data: [],
      },
      {
        id: "unavailable",
        name: "Unavailable (Synced with another client)",
        icon: "person-x",
        showCount: false,
        data: [],
      },
    ];

    availableContacts.forEach((contact) => {
      const contactData = client?.contacts.find(
        (c: any) => c.id === contact.id,
      );

      if (contact.is_available) {
        groupData[0].data.push({
          id: contact.id,
          name: contact.name,
          subName: capitalize(contactData?.contact_type),
          is_available: true,
        });
      } else {
        groupData[1].data.push({
          id: contact.id,
          name: contact.name,
          disabled: true,
          is_available: false,
        });
      }
    });

    return groupData;
  }, [availableContacts, client?.contacts]);

  return (
    <CpModal show={show} onClose={onClose}>
      <CpModal.Header title="Edit Contact Selection" />
      <CpModal.Body>
        <div>
          Select an available contact to populate the Canopy client information.
        </div>
        {show && (
          <CpWell className="cp-mt-8 cp-p-16">
            <div className="cp-wt-semibold">Contact Info</div>
            <div className="cp-mt-16">
              <CpSelectSingle
                data={data}
                isGroupData
                onChange={updateSelectedContact}
                value={selectedContact}
              />
            </div>
            {selectedContactData?.email && (
              <div className="cp-mt-16">
                <CpIcon name="communication-envelope" />
                <span className="cp-ml-16">{selectedContactData.email}</span>
              </div>
            )}
            {selectedContactData?.phone && (
              <div className="cp-mt-16">
                <CpIcon name="communication-phone" />
                <span className="cp-ml-16">
                  {phoneNumber(selectedContactData.phone)}
                </span>
              </div>
            )}
            {selectedContactData?.address && (
              <div className="cp-mt-16 cp-flex">
                <CpIcon name="misc-map-pin" />
                <div className="cp-ml-16 cp-flex-column">
                  <span>{`${selectedContactData.address.address_1} ${selectedContactData.address.address_2}`}</span>
                  <span>{`${selectedContactData.address.locality}${selectedContactData.address.locality ? ", " : " "}${selectedContactData.address.region} ${selectedContactData.address.postal_code}`}</span>
                </div>
              </div>
            )}
          </CpWell>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            onSave(selectedContact);
            onClose();
          }}
          disabled={!selectedContact?.is_available}
          className="cp-mr-8"
        >
          Save
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};

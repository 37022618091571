import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import { commonCss } from "../sync-preview.styles";
import { getClientHeaderRow } from "../sync-preview.helpers";
import { CanopyClientRow } from "./client-row.component";

export default function ClientUnmatchedCanopySection({
  unmatchedCanopyClients,
  readOnly,
  newCrmEnabled,
}) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell className="section-instructions">
        <div className="instructions-description">
          <div className="cp-flex-spread-center" style={{ minWidth: "70rem" }}>
            <div className="cp-title cp-wt-semibold">
              Unmatched Canopy Clients
            </div>
            <a
              href="https://support.getcanopy.com/hc/en-us/articles/11126044366875-Resolve-Duplicate-and-Unmatched-Clients"
              rel="noreferrer"
              target="_blank"
              className="cp-mr-16"
            >
              Learn how to fix unmatched clients
            </a>
          </div>
          <div className="cp-pv-8">
            <p>
              These are Canopy clients that are not found in QuickBooks Online.
            </p>
            {!readOnly && (
              <p
                className="cp-color-app-warning-text"
                style={{ marginTop: "-10px" }}
              >
                Unmatched Canopy clients will not sync.
              </p>
            )}
          </div>
        </div>
      </CpWell>

      {!isEmpty(unmatchedCanopyClients) && (
        <div className="clients-display">
          <table className="client-table">
            {getClientHeaderRow(newCrmEnabled)}
            <tbody>
              {unmatchedCanopyClients.map((client, idx) => {
                return (
                  <CanopyClientRow
                    key={`canopy-unmatched-client-${client.id}-${idx}`}
                    canopyClient={client}
                    newCrmEnabled={newCrmEnabled}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { cdnImage, CpButton } from "canopy-styleguide!sofe";

export function CanopyServiceItemRow({ canopySI }) {
  const serviceItem = {
    id: canopySI.id,
    name: canopySI?.name,
    description: canopySI?.description,
    rate: canopySI.amount,
    code: canopySI?.code,
    taxRate: canopySI.tax_rate_percent,
    rateType: canopySI?.rate_type,
    image: {
      src: "black-delta-mark.svg",
      height: "16rem",
      alt: "Canopy Logo",
    },
  };

  return <ServiceItemRow serviceItem={serviceItem} />;
}

export function QboServiceItemRow({ qboSI }) {
  const serviceItem = {
    id: qboSI.third_party_item_id,
    name: qboSI?.name,
    description: qboSI?.description,
    rate: qboSI.amount,
    code: qboSI?.code,
    taxRate: qboSI.tax_rate_percent,
    rateType: qboSI?.rate_type,
    image: {
      src: "qbo_logo_small_circle.svg",
      height: "20rem",
      alt: "QuickBooks Online Logo",
    },
  };

  return <ServiceItemRow serviceItem={serviceItem} />;
}

function ServiceItemRow({ serviceItem }) {
  const { description, rate, rateType, taxRate } = serviceItem;
  const [expanded, setExpanded] = useState(false);

  const blankField = "——";
  let descriptionText = (
    <td className="large-col">{description || blankField}</td>
  );

  if (description && description.length > 110) {
    const slicedDescription = description.slice(0, 110);

    descriptionText = (
      <td className="large-col">
        {expanded ? description : `${slicedDescription}...`}
        <CpButton
          btnType="flat"
          onClick={() => setExpanded(!expanded)}
          style={{
            paddingLeft: expanded ? 16 : 4,
            paddingRight: expanded ? 16 : 4,
          }}
        >
          {expanded ? "hide" : "read more"}
        </CpButton>
      </td>
    );
  }

  const rateFormatted =
    (rate && rate !== "") || rate === 0
      ? `${parseFloat(rate).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`
      : blankField;

  const rateTypeFormatted = rateType
    ? `${rateType[0].toUpperCase()}${rateType.slice(1)}`
    : blankField;

  return (
    <tr className="border-top">
      <td
        className="sticky-cell"
        style={{
          borderLeft: "0.1rem solid var(--cp-color-card-border)",
          borderRight: "0.1rem solid var(--cp-color-card-border)",
        }}
      >
        <div
          className="client-name-cell"
          style={{ borderRight: 0, borderLeft: 0 }}
        >
          <div className="name-initials">
            <div className="client-name">{serviceItem?.name || blankField}</div>
          </div>
          <div className="logo-right">
            <img
              src={cdnImage(serviceItem.image.src)}
              height={serviceItem.image.height}
              alt={serviceItem.image.alt}
            />
          </div>
        </div>
      </td>
      {descriptionText}
      <td className="small-col">{rateFormatted}</td>
      <td className="small-col">{rateTypeFormatted}</td>
      <td className="small-col">
        {taxRate || taxRate === 0 ? `${parseFloat(taxRate)}%` : blankField}
      </td>
      <td className="small-col">{serviceItem.code || blankField}</td>
    </tr>
  );
}

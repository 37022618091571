import React from "react";
import { useCss, k, a } from "kremling";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";

import ImportProgressBar from "./import-progress-bar.component";
import { isPaidClientLimitModelTier } from "cp-client-auth!sofe";

export default function ClientLimitInfo({
  clientsUsed,
  clientLimit,
  qboActiveClients = 0,
  inactiveClients = 0,
  showPurchaseClientModal,
  loading,
}) {
  const scope = useCss(css);
  const totalAfterImport = clientsUsed + qboActiveClients;
  const clientExceededAmount = totalAfterImport - clientLimit;
  const isPaid = isPaidClientLimitModelTier(clientLimit);

  return (
    <div className="client-limit-box" {...scope}>
      {loading ? (
        <CpLoader />
      ) : (
        <>
          <div className="cp-mb-16">Canopy client limit status:</div>
          <ImportProgressBar
            className="cp-mb-24"
            max={clientLimit}
            current={clientsUsed}
            importing={qboActiveClients}
          />
          <div className="divider" />
          <div className="cp-flex cp-mv-8">
            <div className="color-square bg-primary" />
            Active Canopy clients
          </div>
          <div className="cp-ml-24 cp-mt-8 cp-mb-16">
            <span style={{ fontSize: 10 }}>Count: </span>
            <strong>{clientsUsed}</strong>
          </div>
          <div className="cp-flex cp-mv-8">
            <div className="color-square bg-blue-candy" />
            New QBO active clients
          </div>
          <div className="cp-ml-24 cp-mt-8 cp-mb-16">
            <span style={{ fontSize: 10 }}>Count: </span>
            <strong>{qboActiveClients}</strong>
          </div>
          <div className="cp-flex cp-mv-8">New QBO inactive clients</div>
          <div className="cp-ml-24 cp-mt-8 cp-mb-16">
            <span style={{ fontSize: 10 }}>Count: </span>
            <strong>{inactiveClients}</strong>
          </div>
          <div className="cp-flex cp-mv-8">
            Total active clients after import:{" "}
            <span
              className={a("import-total cp-ml-8").t(
                "total-warning",
                "total-green",
                clientExceededAmount > 0,
              )}
            >
              {totalAfterImport}
            </span>
          </div>
          <div className="cp-pv-8">
            <span className="cp-color-app-primary">*</span>Inactive clients do
            not impact your plan
          </div>
          {clientExceededAmount > 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <i>
                <span className="cp-color-app-warning-text">*</span>You will
                exceed your client limit by
                <strong> {clientExceededAmount}</strong>
                {clientExceededAmount > 1 ? " clients" : " client"}. Increase
                your client limit.
              </i>
              <CpButton btnType="flat" onClick={showPurchaseClientModal}>
                {isPaid ? "Purchase clients" : "Upgrade plan"}
              </CpButton>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const css = k`
  .client-limit-box {
    border: 0.1rem solid var(--cp-color-card-border);
    border-radius: 0.1rem;
    width: 25.5rem;
    padding: 1.6rem;
    box-shadow: 0 1rem 3rem -2.4rem var(--cp-color-well-border);
    margin-bottom: 2.4rem;
  }

  .divider {
    background-color: var(--cp-color-card-border);
    height: 0.1rem;
    margin: 1.6rem 0;
  }

  .color-square {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.4rem;
    margin-right: 0.8rem
  }

  .bg-primary {
    background-color: var(--cp-color-app-primary);
  }

  .bg-gray {
    background-color: var(--cp-color-app-border);
  }

  .bg-blue-candy {
    background-color: #A5C8FD;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 5px,
      #fff 5px,
      #fff 7px
    );
  }

  .import-total {
    font-weight: bold;
    width: 42px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .total-warning {
    color: var(--cp-color-app-warning-text);
  }

  .total-green {
    color: #009e38;
  }
`;

import React from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

export function MatchSyncedClientModal({
  show,
  close,
  name,
  match,
}: {
  show: boolean;
  close: () => void;
  name: string | undefined;
  match: () => void;
}) {
  return (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title="Match an already synced client" />
      <CpModal.Body>
        The selected QBO customer is already synced with another Canopy client.
        If you choose to match this QBO customer with {name}, the original
        synced match will be broken.
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            match();
            close();
          }}
          className="cp-mr-8"
        >
          Match
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

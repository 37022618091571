import ActionsCell from "./actions-cell.component";

export const getSettingsListSchema = () => ({
  sectionName: {
    fieldId: "sectionName",
    label: "Sections",
    dataType: "string",
  },
  count: {
    fieldId: "count",
    label: "Count",
    dataType: "number",
  },
  actions: {
    fieldId: "status",
    label: "Status",
    dataType: "string",
    cell: {
      component: ActionsCell,
      props: ({ resource, value }: { resource: object; value: string }) => ({
        status: value,
        sectionObj: resource,
      }),
    },
  },
});

export const sectionMap = () => ({
  clients: "Clients",
  items: "Service Items",
  invoices: "Invoices",
  payments: "Payments and Credits",
});

export const sectionList = ["clients", "items", "invoices", "payments"];

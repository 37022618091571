import React from "react";

export function QboIcon() {
  return (
    <img
      src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
      alt="QBO"
      style={{ height: "2rem", width: "2rem", verticalAlign: "middle" }}
    />
  );
}

import React, { useState, useEffect, useRef } from "react";
import {
  CpOverlay,
  CpButton,
  CpModal,
  CpIcon,
  cdnImage,
} from "canopy-styleguide!sofe";
import { k, useCss } from "kremling";
import { handleError } from "src/handle-error";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

import {
  getIntegrations,
  updatePaymentsSyncStatus,
} from "../../integrations.resource.js";
import type { IntegrationInfo } from "../../types/integration.d.ts";

export const AdjustQboSettingOverlay = ({
  show,
  close,
  refreshIntegrations,
}: {
  show: boolean;
  close: () => void;
  refreshIntegrations: () => void;
}) => {
  const scoped = useCss(css);
  const syncPaymentsSub = useRef<any>();
  const getIntegrationsSub = useRef<any>();
  const [user] = useWithUserAndTenant();
  const [syncLoading, setSyncLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    return () => {
      syncPaymentsSub.current?.unsubscribe();
      getIntegrationsSub.current?.unsubscribe();
    };
  }, []);

  const getIntegrationsSync = () => {
    setSyncLoading(true);
    setShowWarning(false);
    getIntegrationsSub.current = getIntegrations().subscribe(
      (integrations: Array<IntegrationInfo>) => {
        const qboConnection = integrations.find(
          (integration) => integration.type === "qbo",
        );

        if (
          qboConnection &&
          !qboConnection.preferences?.SalesFormsPrefs?.AutoApplyCredit
        ) {
          updatePaymentsSync(qboConnection);
        } else {
          setSyncLoading(false);
          setShowWarning(true);
        }
      },
      (err: Error) => {
        setSyncLoading(false);
        handleError(err);
      },
    );
  };

  const updatePaymentsSync = (qboConnection: IntegrationInfo) => {
    syncPaymentsSub.current = updatePaymentsSyncStatus(
      qboConnection.id,
      qboConnection.type,
      true,
      user,
    ).subscribe(
      () => {
        setSyncLoading(false);
        refreshIntegrations();
        setShowConfirmation(true);
      },
      (err: Error) => {
        setSyncLoading(false);
        handleError(err);
      },
    );
  };

  const onClose = () => {
    close();
    setShowWarning(false);
    setShowConfirmation(false);
  };

  return (
    <CpOverlay show={show} onClose={onClose}>
      <CpOverlay.Header title="QBO Payment and Credit Sync">
        <div className="cp-flex-center">
          {showWarning && (
            <div className="cp-mr-8 cp-color-app-warning-text">
              <CpIcon
                name="alert-triangle-filled-small"
                fill="var(--cp-color-app-warning-text)"
              />
              The setting must be OFF to resume your sync
            </div>
          )}
          {!showConfirmation && (
            <>
              <CpButton btnType="flat" onClick={onClose}>
                Cancel
              </CpButton>
              <CpButton
                btnType="primary"
                onClick={getIntegrationsSync}
                className="cp-ml-8"
                showLoader={syncLoading}
              >
                Resume sync
              </CpButton>
            </>
          )}
          {showConfirmation && <CpButton onClick={onClose}>Done</CpButton>}
        </div>
      </CpOverlay.Header>
      <CpOverlay.Body>
        {!showConfirmation && <AdjustQboSettingsBody mode="Resume" />}
        {showConfirmation && (
          <div {...scoped} className="instructions">
            <div className="instructions-left">
              <img
                src={cdnImage("payment_success.svg")}
                height={380}
                alt="Success Illustration"
                style={{ marginTop: "-2.4rem" }}
              />
            </div>
            <div className="instructions-right">
              <div className="instructions-header cp-wt-semibold">
                Payments & credits sync is successfully resumed
              </div>
              <div className="instruction cp-pt-8 cp-pb-16">
                Now, when new payments/credits are created in Canopy they will
                be automatically reflected in QuickBooks Online.
              </div>
            </div>
          </div>
        )}
      </CpOverlay.Body>
    </CpOverlay>
  );
};

export const AdjustQboSettingsBody = ({ mode = "Resume" }) => {
  const scoped = useCss(css);

  return (
    <div {...scoped} className="instructions">
      <div className="instructions-left">
        <img
          src={cdnImage("computer_toggles.svg")}
          height={400}
          alt="Payments Illustration"
        />
      </div>
      <div className="instructions-right">
        <div className="instructions-header cp-wt-semibold">
          Adjust your QBO setting
        </div>
        <div className="instruction cp-pt-8 cp-pb-16">
          To avoid balance mismatches, the following automation setting should
          be turned off in QBO.
        </div>
        <div className="instruction-subheader">Steps for success:</div>
        <ul>
          <li>
            Sign into{" "}
            <a
              href="https://app.qbo.intuit.com/app/settings?p=advanced"
              target="_blank"
              rel="noreferrer"
            >
              QuickBooks Online
            </a>{" "}
            and navigate to the settings menu.
          </li>
          <li>
            Select &quot;Company settings&quot; and then &quot;Advanced.&quot;
          </li>
          <li>
            Locate the automation setting titled, &quot;Automatically apply
            credits,&quot; and turn the setting OFF.
          </li>
          <li>
            {`Once the QBO setting is turned off, return to Canopy and click "${mode} sync."`}
          </li>
        </ul>
      </div>
    </div>
  );
};

export const ResumePaymentSyncModal = ({
  show,
  close,
  showAdjustQboSettingsOverlay,
}: {
  show: boolean;
  close: () => void;
  showAdjustQboSettingsOverlay: () => void;
}) => {
  return (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title="Resume payment sync" />
      <CpModal.Body>
        <div>
          Before your Payment sync is resumed, please follow the following
          instructions to turn OFF one of your QBO automation settings.
        </div>
        <div className="cp-mt-16 cp-mb-8">
          This is a crucial step that will prevent balance mismatches between
          QBO and Canopy.
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            showAdjustQboSettingsOverlay();
            close();
          }}
          className="cp-mr-8"
        >
          View instructions
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};

const css = k`
  .instructions {
    display: flex;
    justify-content: center;
    margin: 14rem 2rem 0;
  }

  .instructions-right {
    margin-left: 4rem;
    width: 45rem;
  }

  .instructions-left {
    display: flex;
    align-items: center;
    min-width: 42rem;
  }

  .instructions-header {
    font-size: 3.2rem;
    padding: 0.8rem 0 0.8rem 0;
    max-width: 45rem;
    font-weight: 600;
  }

  .instruction {
    max-width: 55rem;
    font-size: 1.6rem;
    color: var(--cp-color-app-secondary-text);
  }

  .instructions li {
    font-size: 1.6rem;
    color: var(--cp-color-app-secondary-text);
    padding-top: 2.4rem;
  }

  .instruction-subheader {
    font-size: 1.6rem;
    color: var(--cp-color-app-primary-text);
    font-weight: 600;
  }
`;

import React from "react";
import { useCss, k } from "kremling";
import {
  CpButton,
  CpModal,
  CpEmptyState,
  constants,
} from "canopy-styleguide!sofe";
import { isPaidClientLimitModelTier } from "cp-client-auth!sofe";

export default function PurchaseClientsModal({ show, close, clientLimit }) {
  const scoped = useCss(css);
  const isPaid = isPaidClientLimitModelTier(clientLimit);
  return isPaid ? (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title="Purchase clients" />
      <CpModal.Body>
        In order to sync all of your QuickBooks Online clients, you will need to
        purchase more clients. Once you have purchased more clients, try syncing
        again.
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          href="./#/global-settings/account-management"
          anchor
        >
          Purchase clients
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  ) : (
    <CpModal show={show} onClose={close} width={560}>
      <div {...scoped}>
        <div className="freemium-upgrade-close">
          <CpButton onClick={close} icon="close-large" aria-label="Close" />
        </div>
        <div className="freemium-upgrade">
          <CpEmptyState img="es_clients" text="Time to upgrade" />
          <div className="cp-mt-16">
            You&apos;ve hit your maximum of <strong>{clientLimit}</strong>{" "}
            clients for your pricing tier. Call{" "}
            <strong>{constants.canopyPhone}, ext. 1</strong> to upgrade.
          </div>
        </div>
      </div>
    </CpModal>
  );
}

const css = k`
  .freemium-upgrade {
    padding: 0 8.8rem 8rem;
    text-align: center;
  }

  .freemium-upgrade-close {
    display: flex;
    justify-content: flex-end;
    padding: 10px 12px;
  }
`;

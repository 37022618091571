import React, { useState, useRef, useEffect } from "react";
import {
  CpOverlay,
  CpButton,
  CpIcon,
  CpWell,
  cdnImage,
  CpToggle,
  CpTooltip,
} from "canopy-styleguide!sofe";
import { useCss, k, a } from "kremling";
import { DateTime } from "luxon";
import { handleError } from "src/handle-error";
import { cloneDeep } from "lodash";

import type {
  IntegrationInfo,
  IntegrationSettings,
  IntegrationSettingFromCanopy,
  IntegrationSettingFromThirdParty,
} from "../types/integration.d.ts";
import { patchSettings } from "../integrations.resource.js";

export default function IntegrationSettingsOverlay({
  show,
  close,
  integrationInfo,
  setIntegrationInfo,
}: {
  show: boolean;
  close: () => void;
  integrationInfo: IntegrationInfo;
  setIntegrationInfo: (integrationInfo: IntegrationInfo) => void;
}) {
  const scoped = useCss(css);
  const saveSettingsSub = useRef<any>();
  const [settings, setSettings] = useState(integrationInfo.clients.settings);
  const [saving, setSaving] = useState(false);

  // this will be a state variable in the future
  const selectedTab = "clients";

  useEffect(() => {
    if (integrationInfo.clients.settings) {
      setSettings(integrationInfo.clients.settings);
    }

    return () => {
      saveSettingsSub.current?.unsubscribe();
    };
  }, [integrationInfo.clients.settings]);

  // return navigation item
  const getNavItem = (
    tabName: string,
    icon: string,
    text: string,
    disabled: boolean,
  ) => (
    <div
      className={a("setting-nav-item")
        .m(
          "cp-color-app-primary tab-selected",
          selectedTab === tabName && !disabled,
        )
        .m("cp-color-app-disabled-text", disabled)}
    >
      <CpIcon
        name={icon}
        fill={
          disabled
            ? "var(--cp-color-app-disabled-text)"
            : selectedTab === tabName
              ? "var(--cp-color-app-primary)"
              : "var(--cp-color-app-secondary-text)"
        }
        className="cp-mr-16"
      />
      <span>{text}</span>
    </div>
  );

  const updateSettingState = ({
    isChecked,
    actionType,
    toggleTypeCanopy,
    toggleTypeThirdParty,
  }: {
    isChecked: boolean;
    actionType: keyof IntegrationSettings;
    toggleTypeCanopy?: keyof IntegrationSettingFromCanopy;
    toggleTypeThirdParty?: keyof IntegrationSettingFromThirdParty;
  }) => {
    const newSettings = cloneDeep(settings) as IntegrationSettings;

    if (actionType === "actions_from_canopy" && toggleTypeCanopy) {
      newSettings[actionType][toggleTypeCanopy] = isChecked
        ? DateTime.now().toUTC().toISO()
        : null;
    } else if (
      actionType === "actions_from_third_party" &&
      toggleTypeThirdParty
    ) {
      newSettings[actionType][toggleTypeThirdParty] = isChecked
        ? DateTime.now().toUTC().toISO()
        : null;
    }

    setSettings(newSettings);
  };

  const saveSettings = () => {
    // save settings
    saveSettingsSub.current = patchSettings(integrationInfo.id, {
      clients: { settings },
    }).subscribe(
      (integrations: Array<IntegrationInfo>) => {
        const qboConnection: IntegrationInfo | undefined = integrations.find(
          (integration: IntegrationInfo) => integration.type === "qbo",
        );

        if (qboConnection) {
          setIntegrationInfo(qboConnection);
        }

        setSaving(false);
        close();
      },
      (error: Error) => {
        setSaving(false);
        handleError(error);
      },
    );
  };

  const onClose = () => {
    close();
    setSettings(integrationInfo.clients.settings);
  };

  return (
    <CpOverlay show={show} onClose={onClose}>
      <CpOverlay.Header title="QBO Integration Settings">
        <CpButton
          className="cp-mr-16"
          btnType="secondary"
          onClick={onClose}
          disabled={saving}
        >
          Cancel
        </CpButton>
        <CpButton btnType="primary" onClick={saveSettings} showLoader={saving}>
          Save
        </CpButton>
      </CpOverlay.Header>
      <CpOverlay.Body>
        <div {...scoped} className="cp-flex">
          <CpWell className="left-settings-nav">
            {getNavItem("clients", "person", "Clients", false)}
          </CpWell>
          <CpWell className="settings-selector">
            <div className="cp-flex-spread cp-mb-16 cp-mr-4">
              <div className="cp-subheader-sm cp-wt-semibold">Client Sync</div>
              <div>
                <CpIcon
                  name="shape-circle-closed"
                  fill="var(--cp-color-app-success-text)"
                  className="cp-mr-4"
                />
                Synced
              </div>
            </div>
            <div>
              <div className="two-way-info">
                <CpIcon
                  name="information-circle-open-small"
                  fill="var(--cp-color-app-secondary-text)"
                  className="cp-mr-8"
                />
                <div>
                  <strong>Two-way Sync</strong>
                  <div>
                    New clients created in Canopy or edits made to a client in
                    Canopy will have the ability to sync to QBO and vice versa.
                  </div>
                </div>
              </div>
              <div className="cp-flex cp-mt-24">
                <div className="actions-section">
                  <div className="cp-flex-center cp-body">
                    <img
                      src={cdnImage("black-delta-mark.svg")}
                      height={24}
                      className="cp-mr-8"
                    />
                    <strong>Actions from Canopy</strong>
                  </div>
                  <div className="cp-ml-40">
                    <div className="cp-body cp-mb-8 cp-mt-32">
                      <strong>Creating new clients in Canopy</strong>
                    </div>
                    <div>
                      <CpToggle
                        className="cp-mr-8"
                        checked={
                          !!settings?.actions_from_canopy
                            ?.canopy_clients_create_in_third_party_default
                        }
                        onChange={(isChecked) => {
                          updateSettingState({
                            isChecked,
                            actionType: "actions_from_canopy",
                            toggleTypeCanopy:
                              "canopy_clients_create_in_third_party_default",
                          });
                        }}
                      />
                      Default state for the QBO &quot;sync client&quot; toggle
                      <CpTooltip
                        position="bottom-end"
                        text="Set the default to align with your more likely action. If most clients will need to sync in QBO, the toggle should default ON. Whatever the state of this toggle is, will be reflected as the default toggle state when you are creating a new client in Canopy."
                      >
                        <CpIcon
                          className="cp-ml-4"
                          name="information-circle-open-small"
                          fill="var(--cp-color-app-secondary-text)"
                        />
                      </CpTooltip>
                    </div>
                    <div className="cp-body cp-mb-8 cp-mt-32">
                      <strong>Editing clients in Canopy</strong>
                    </div>
                    <div>
                      <CpToggle
                        className="cp-mr-8"
                        checked={
                          !!settings?.actions_from_canopy
                            ?.canopy_clients_update_in_third_party
                        }
                        onChange={(isChecked) => {
                          updateSettingState({
                            isChecked,
                            actionType: "actions_from_canopy",
                            toggleTypeCanopy:
                              "canopy_clients_update_in_third_party",
                          });
                        }}
                      />
                      Edits to a client should sync to QBO
                    </div>
                  </div>
                </div>
                <hr className="vertical-line" />
                <div className="actions-section">
                  <div className="cp-flex-center cp-body">
                    <img
                      src={cdnImage("qbo_logo_small_circle.svg")}
                      height={24}
                      className="cp-mr-8"
                    />
                    <strong>Actions from QBO</strong>
                  </div>
                  <div className="cp-ml-40">
                    <div className="cp-body cp-mb-8 cp-mt-32">
                      <strong>Creating new clients in QBO</strong>
                    </div>
                    <div>
                      <CpToggle
                        className="cp-mr-8"
                        checked={
                          !!settings?.actions_from_third_party
                            ?.third_party_clients_create_in_canopy
                        }
                        onChange={(isChecked) => {
                          updateSettingState({
                            isChecked,
                            actionType: "actions_from_third_party",
                            toggleTypeThirdParty:
                              "third_party_clients_create_in_canopy",
                          });
                        }}
                      />
                      Clients should auto-create in Canopy
                    </div>
                    <div className="cp-body cp-mb-8 cp-mt-32">
                      <strong>Editing clients in QBO</strong>
                    </div>
                    <div>
                      <CpToggle
                        className="cp-mr-8"
                        checked={
                          !!settings?.actions_from_third_party
                            ?.third_party_clients_update_in_canopy
                        }
                        onChange={(isChecked) => {
                          updateSettingState({
                            isChecked,
                            actionType: "actions_from_third_party",
                            toggleTypeThirdParty:
                              "third_party_clients_update_in_canopy",
                          });
                        }}
                      />
                      Edits should sync to Canopy
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CpWell>
        </div>
      </CpOverlay.Body>
    </CpOverlay>
  );
}

const css = k`
  .left-settings-nav {
    width: 19rem;
    height: fit-content;
    padding: 1.2rem 0;
  }

  .settings-selector {
    margin-left: 16px;
    width: 95rem;
    height: 40rem;
    padding: 1.6rem;
  }

  .two-way-info {
    border: 1px solid var(--cp-color-well-border);
    border-radius: 0.8rem;
    padding: 1.6rem 2.4rem;
    display: flex;
  }

  .actions-section {
    width: 50%;
  }

  .vertical-line {
    width: 1px;
    height: 20rem;
    background-color: var(--cp-color-well-border);
    margin: 0 16px;
  }

  .tab-selected {
    border-left: 2px solid var(--cp-color-app-primary);
  }

  .setting-nav-item {
    display: flex;
    align-items: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    padding-left: 1.6rem;
  }
`;

import React from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";
import { k, useCss } from "kremling";

export default function DisconnectModal({
  show,
  close,
  disconnectIntegration,
}: {
  show: boolean;
  close: () => void;
  disconnectIntegration: () => void;
}) {
  const scope = useCss(css);

  return (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title="Disconnect QBO Integration" />
      <CpModal.Body>
        <div>
          By disconnecting the integration, all established connections between
          Canopy and QBO clients/service items will be lost.
        </div>
        <ul {...scope}>
          <li className="disconnect-li">
            Those connections will have to be reconfigured if the integration is
            ever resumed.
          </li>
          <li className="disconnect-li">
            Payment, credit, and invoice connections will not be deleted but
            cannot be viewed while the integration is disconnected.
          </li>
        </ul>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            disconnectIntegration();
            close();
          }}
          className="cp-mr-8"
        >
          Disconnect
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

const css = k`
  .disconnect-li {
    margin-top: 1.6rem;
    margin-left: -1.2rem;
  }
`;

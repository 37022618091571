import React, { useState, useEffect, useMemo } from "react";
import { useCss, k, m } from "kremling";
import { QboIcon } from "../common/qbo-icon.component";
import {
  CpEmptyState,
  CpInput,
  CpLoader,
  CpPillToggle,
  CpRadio,
  phoneNumber,
} from "canopy-styleguide!sofe";
import { useDebounceValue } from "../../use-debounce-value";
import { qboSearch } from "../../../integrations.resource";
import { IntegrationInfo } from "../../../types/integration";
import { Client, ThirdPartyEntity } from "../../../types/client";
import { thirdPartyUrl } from "../common/third-party-url";

type Props = {
  integration: IntegrationInfo;
  setSelectedQboClient: (
    selectedQboClient: ThirdPartyEntity | undefined,
  ) => void;
  selectedQboClient: ThirdPartyEntity | undefined;
  client: Client;
  newCrmEnabled: boolean;
};

export function RowSearchClients(props: Props) {
  const {
    integration,
    setSelectedQboClient,
    client,
    selectedQboClient,
    newCrmEnabled,
  } = props;
  const scope = useCss(css);
  const [qboList, setQboList] = useState<ThirdPartyEntity[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [selected, setSelected] = useState<string | undefined>(
    selectedQboClient?.third_party_client_id,
  );
  const [filter, setFilter] = useState<"email" | "display_name">(
    "display_name",
  );

  const debouncedSearch = useDebounceValue(search, 700);

  const clientPrimaryEmail = useMemo(() => {
    return client.emails?.find((e) => e.is_primary) || { value: "" };
  }, [client.emails]);

  useEffect(() => {
    if (debouncedSearch.length < 3 && debouncedSearch.length !== 0) {
      setQboList([]);
    } else {
      setLoading(true);
      const sub = qboSearch(
        integration.id,
        debouncedSearch ||
          (filter === "display_name"
            ? client.display_name || client.name
            : clientPrimaryEmail?.value),
        filter,
      ).subscribe((results: ThirdPartyEntity[]) => {
        setSelected(undefined);
        setQboList(results);
        setLoading(false);
      });
      return () => {
        sub?.unsubscribe();
        setLoading(false);
      };
    }
  }, [client, debouncedSearch, filter, integration]);

  useEffect(() => {
    setSelectedQboClient(undefined);
  }, [qboList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedQboClient(
      qboList.find((q) => q.third_party_client_id === selected),
    );
  }, [setSelectedQboClient, qboList, selected]);

  return (
    <div {...scope}>
      <div className="ie-table">
        <div className="search-clients-box">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center cp-gap-16">
                <strong>Search QBO based on:</strong>{" "}
                <CpPillToggle
                  text="Display name"
                  checked={filter === "display_name"}
                  onChange={() => setFilter("display_name")}
                />
                <CpPillToggle
                  text="Email"
                  checked={filter === "email"}
                  onChange={() => setFilter("email")}
                />
              </div>
              <div>
                {qboList.length || 0} Result{qboList.length === 1 ? "" : "s"}
              </div>
            </div>
            <div style={{ width: 300, height: "5.2rem" }}>
              <div>
                <CpInput
                  isSearch
                  placeholder="Search clients..."
                  onChange={setSearch}
                  value={search}
                />
                {search.length > 0 && search.length < 3 && (
                  <div className="error-message">
                    Min of 3 characters required to search
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CpRadio
          name="qbo-select"
          value={selected}
          onChange={(val) => setSelected(val as string)}
        >
          <table className="ie-table-selectable">
            <thead>
              <tr>
                <td>{newCrmEnabled ? "Client name" : "Display name"}</td>
                <td></td>
                <td>
                  {newCrmEnabled
                    ? client.is_business
                      ? "Business Name"
                      : "Contact"
                    : "Client name"}
                </td>
                <td>Phone number</td>
                <td>Email</td>
                <td>Street address</td>
                <td>City</td>
                <td>State</td>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                qboList.map((q) => {
                  const phone = newCrmEnabled
                    ? { value: q.phone }
                    : q.phones?.find((p) => p.value);
                  const email = newCrmEnabled
                    ? { value: q.email }
                    : q.emails?.find((e) => e.value);
                  const address = q.addresses?.find((e) => e.value);
                  return (
                    <tr
                      className={m(
                        "--selected",
                        q.third_party_client_id === selected,
                      )}
                      key={q.third_party_client_id}
                      onClick={() => {
                        setSelected(q.third_party_client_id);
                      }}
                    >
                      <td>
                        <CpRadio.Item id={q.third_party_client_id}>
                          <a
                            href={thirdPartyUrl(q.third_party_client_id)}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {q.display_name}
                          </a>
                        </CpRadio.Item>
                      </td>
                      <td>
                        <QboIcon />
                      </td>
                      <td>
                        {newCrmEnabled
                          ? q.name
                          : q.is_business
                            ? q.business_name
                            : `${q.first_name} ${q.last_name}`}
                      </td>
                      <td>{phone?.value ? phoneNumber(phone.value) : "-"}</td>
                      <td>{email?.value || "-"}</td>
                      <td>
                        {newCrmEnabled ? (
                          q.street_address?.trim() || "-"
                        ) : (
                          <>
                            <div>{address?.value?.address_1 || "-"}</div>
                            {address?.value?.address_1 &&
                              address?.value?.address_2 && (
                                <div>{address.value.address_2}</div>
                              )}
                          </>
                        )}
                      </td>
                      <td>
                        {newCrmEnabled
                          ? q.locality || "-"
                          : address?.value?.locality || "-"}
                      </td>
                      <td>
                        {newCrmEnabled
                          ? q.region || "-"
                          : address?.value?.region || "-"}
                      </td>
                    </tr>
                  );
                })}
              {(loading || !qboList.length) && (
                <tr>
                  <td colSpan={8}>
                    <div className="cp-text-center cp-mv-32">
                      {!loading && !qboList.length && (
                        <CpEmptyState
                          text="No QBO clients"
                          img="es_clients_removed"
                          subText="You have no QBO clients to show. Please refine your search"
                        />
                      )}
                      {loading && <CpLoader />}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CpRadio>
      </div>
    </div>
  );
}

const css = k`
  .search-clients-box {
    border-bottom: solid .1rem var(--cp-color-app-border);
    display: flex;
    flex-direction: column;
    padding: 1.2rem 1.6rem;
  }
  .error-message {
    color: var(--cp-color-app-error-text);
    font-size: 1.2rem;
    padding-top: .4rem;
  }
`;

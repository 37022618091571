import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import { commonCss } from "../sync-preview.styles";
import { getServiceItemsHeaderRow } from "../sync-preview.helpers";
import { CanopyServiceItemRow } from "./service-item-row";

export default function ServiceItemUnmatchedSection({
  unmatchedCanopy,
  readOnly,
}) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell className="section-instructions">
        <div className="instructions-description">
          <div className="cp-title cp-wt-semibold">Unmatched Service Items</div>
          <div className="cp-pv-8">
            This is the list of service items in Canopy that do not match
            services in QBO.
          </div>
          {!readOnly && (
            <div className="cp-wt-semibold cp-color-app-warning-text">
              UNMATCHED_CANOPY SERVICE ITEMS WILL NOT SYNC.
            </div>
          )}
        </div>
        <div className="cp-ml-24">
          <div className="cp-wt-semibold cp-pb-16 cp-pl-8">
            How to fix unmatched service items:
          </div>
          <ol className="instructions-steps">
            <li className="instruction-step">
              Open a new tab & navigate to your service items list in Canopy.
            </li>
            <li className="instruction-step">
              Ensure the name, rate and code of each Canopy service item match
              the name, rate and sku of a service in QBO.
            </li>
            <li className="instruction-step">Make changes if needed.</li>
            <li className="instruction-step">
              {readOnly
                ? "Sync service items again."
                : "Exit preview and sync service items again."}
            </li>
          </ol>
        </div>
      </CpWell>

      {!isEmpty(unmatchedCanopy) && (
        <div className="clients-display">
          <table className="client-table">
            {getServiceItemsHeaderRow()}
            <tbody>
              {unmatchedCanopy.map((serviceItem) => {
                return (
                  <CanopyServiceItemRow
                    key={`canopy-si-${serviceItem.id}`}
                    canopySI={serviceItem}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { CpOverlay } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";

import ServiceItemsPreview from "./service-items-preview.component";
import ClientsPreview from "./clients-preview.component";
import PaymentsPreview from "./payments-preview.component";
import IntegrationsReport from "./integrations-report.component";
import { cancelSync } from "../../integrations.resource";
import { useCrmHierarchy } from "../../common/use-crm-hierarchy.helper";
import RestartInstructions from "./restart-instructions.component";

export default function SyncPreviewOverlay({
  showSyncPreview,
  setShowSyncPreview,
  qboIntegrationInfo,
  clientSyncId,
  setClientSyncId,
  showReport,
  hasClientsPermissions,
  hasInvoicePermissions,
  hasPaymentsPermissions,
  syncStartSection = "clients",
}) {
  const [showClientsPreview, setShowClientsPreview] = useState(
    syncStartSection === "clients",
  );
  const [showRestartInstructions, setShowRestartInstructions] = useState(
    syncStartSection === "restart",
  );
  const [showServiceItemsPreview, setShowServiceItemsPreview] = useState(
    syncStartSection === "items" || syncStartSection === "invoices",
  );
  const [showPaymentsPreview, setShowPaymentsPreview] = useState(
    syncStartSection === "payments",
  );
  const [serviceItemId, setServiceItemId] = useState();
  const [newClientId, setNewClientId] = useState();
  const [cancelSyncId, setCancelSyncId] = useState();
  const newCrmEnabled = useCrmHierarchy();

  useEffect(() => {
    if (!cancelSyncId) return;

    const subscription = cancelSync(
      qboIntegrationInfo.id,
      cancelSyncId,
    ).subscribe(
      () => {
        setShowSyncPreview({ showSyncPreview: false });
      },
      (e) => {
        setShowSyncPreview({ showSyncPreview: false });
        handleError(e, {
          showToast: false,
        });
      },
    );

    return () => subscription.unsubscribe();
  }, [cancelSyncId, qboIntegrationInfo.id, setShowSyncPreview]);

  const cancelSyncPreview = () => {
    if (serviceItemId || newClientId) {
      const syncId = serviceItemId || newClientId;

      setCancelSyncId(syncId);
    } else {
      setShowSyncPreview({
        showSyncPreview: false,
        syncPreviewReadOnly: false,
      });
    }
  };

  return (
    <CpOverlay show={showSyncPreview} onClose={cancelSyncPreview}>
      {!showReport && showClientsPreview && (
        <ClientsPreview
          hasClientsPermissions={hasClientsPermissions}
          hasInvoicePermissions={hasInvoicePermissions}
          setClientSyncId={setClientSyncId}
          qboIntegrationInfo={qboIntegrationInfo}
          setShowSyncPreview={setShowSyncPreview}
          clientSyncId={clientSyncId}
          setShowServiceItemsPreview={() => {
            if (hasInvoicePermissions) {
              setShowClientsPreview(false);
              setShowServiceItemsPreview(true);
            } else {
              setShowSyncPreview({
                showSyncPreview: false,
              });
            }
          }}
          setNewClientId={setNewClientId}
          newClientId={newClientId}
          newCrmEnabled={newCrmEnabled}
        />
      )}
      {!showReport && showRestartInstructions && (
        <RestartInstructions
          setShowSyncPreview={setShowSyncPreview}
          setShowServiceItemsPreview={() => {
            setShowRestartInstructions(false);
            setShowServiceItemsPreview(true);
          }}
          hasInvoicePermissions={hasInvoicePermissions}
          qboIntegrationInfo={qboIntegrationInfo}
        />
      )}
      {!showReport && showServiceItemsPreview && (
        <ServiceItemsPreview
          hasInvoicePermissions={hasInvoicePermissions}
          qboIntegrationInfo={qboIntegrationInfo}
          setShowSyncPreview={setShowSyncPreview}
          serviceItemId={serviceItemId}
          clientSyncId={clientSyncId}
          setServiceItemId={setServiceItemId}
          setShowPaymentsPreview={() => {
            setShowServiceItemsPreview(false);
            setShowPaymentsPreview(true);
          }}
          hasPayments={hasPaymentsPermissions}
          syncStartSection={syncStartSection}
        />
      )}
      {!showReport && showPaymentsPreview && (
        <PaymentsPreview
          qboIntegrationInfo={qboIntegrationInfo}
          setShowSyncPreview={setShowSyncPreview}
          hasPaymentsPermissions={hasPaymentsPermissions}
          syncStartSection={syncStartSection}
        />
      )}
      {showReport && (
        <IntegrationsReport
          qboIntegrationInfo={qboIntegrationInfo}
          clientSyncId={clientSyncId}
          setShowSyncPreview={setShowSyncPreview}
          showClientsPreview={showClientsPreview}
          showServiceItemsPreview={showServiceItemsPreview}
          setShowClientsPreview={setShowClientsPreview}
          setShowServiceItemsPreview={setShowServiceItemsPreview}
          newCrmEnabled={newCrmEnabled}
        />
      )}
    </CpOverlay>
  );
}

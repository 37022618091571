import React from "react";
import Disposable from "react-disposable-decorator";
import queryString from "query-string";
import { Scoped, k, maybe } from "kremling";
import { CpButton, CpDropdown, CpIcon, cdnImage } from "canopy-styleguide!sofe";
import { successToast, warningToast } from "toast-service!sofe";
import { handleError } from "src/handle-error";
import { UserTenantProps } from "cp-client-auth!sofe";

import {
  createAuthUrl,
  disconnectAuthUrl,
  getIntegrations,
  getLastSyncInfo,
} from "../integrations.resource";

@Disposable
@UserTenantProps({
  permissions: {
    allClients: "clients_not_assigned",
    clientsCreateEdit: "clients_create_edit",
    invoices: "billing_invoices",
    invoicesCreateEdit: "billing_invoices_create_edit",
    billingSettings: "company_settings_billing",
    payments: "billing_payments",
    paymentsCreateEdit: "billing_payments_create_edit",
  },
})
export default class XeroIntegrationCard extends React.Component {
  state = {
    fetchingConnection: true,
    authUrl: null,
    showSyncPreview: false,
    syncPreviewReadOnly: false,
    xeroIntegrationInfo: {},
    syncId: "",
  };

  componentDidMount() {
    const parsedQueryString = queryString.parse(this.props.location.search);
    const { showData, credentialId = "", syncId = "" } = parsedQueryString;

    this.checkXeroConnection();

    // the param will be here if someone is clicking a link to this page from the email we send, it opens the modal automatically
    const showLastSuccessfulSync = showData && showData === "xero";

    if (showLastSuccessfulSync) {
      this.setState({
        syncId,
        xeroIntegrationInfo: {
          id: credentialId,
        },
        showSyncPreview: true,
        syncPreviewReadOnly: true,
      });
    }
  }

  checkXeroConnection = (popupClosed) => {
    this.props.cancelWhenUnmounted(
      getIntegrations().subscribe(
        (integrations) => {
          const xeroIntegrationInfo = integrations.find(
            (i) => i.type === "xero",
          );
          const xeroDirectConnected = xeroIntegrationInfo?.connected;

          this.setState((prevState) => {
            // show sync preview if user just finished connecting or if they are opening the report from and email
            const showSyncPreview =
              !!(
                popupClosed &&
                !prevState.xeroDirectConnected &&
                xeroDirectConnected
              ) || prevState.showSyncPreview;

            return {
              xeroDirectConnected,
              fetchingConnection: false,
              showSyncPreview,
              xeroIntegrationInfo,
            };
          }, this.getLastSyncInfo(xeroIntegrationInfo));
        },
        (err) => {
          this.setState({
            xeroDirectConnected: false,
            fetchingConnection: false,
          });
          handleError(err);
        },
      ),
    );
  };

  connectXero = () => {
    this.setState({ fetchingConnection: true });

    this.props.cancelWhenUnmounted(
      createAuthUrl("xero").subscribe(
        (authUrl) => {
          const oauthWindow = window.open(
            authUrl,
            "Xero Login",
            "width=800,height=800",
          );

          // when the window closes check the current xero connection status again
          const checkOauthWindow = () => {
            if (oauthWindow.closed) {
              this.checkXeroConnection(true);
            } else {
              setTimeout(checkOauthWindow, 2000);
            }
          };
          // don't check if the window is closed the first time for 10 seconds
          setTimeout(checkOauthWindow, 10000);
        },
        (err) => {
          this.setState({ fetchingConnection: false });
          handleError(err);
        },
      ),
    );
  };

  disconnectXero = () => {
    this.setState({ fetchingConnection: true });
    this.props.cancelWhenUnmounted(
      disconnectAuthUrl(this.state.xeroIntegrationInfo.id, "xero").subscribe(
        (result) => {
          let xeroDirectConnected = false;
          if (result?.success) {
            successToast("Xero was disconnected successfully");
          } else {
            xeroDirectConnected = true;
            warningToast("Xero was not disconnected successfully");
          }

          this.setState({
            xeroDirectConnected,
            fetchingConnection: false,
          });
        },
        (err) => {
          this.setState({ fetchingConnection: false });
          handleError(err);
        },
      ),
    );
  };

  getLastSyncInfo = (xeroIntegrationInfo) => {
    if (
      xeroIntegrationInfo?.connected &&
      this.props.permissions.hasIntegrationsPermissions
    ) {
      this.props.cancelWhenUnmounted(
        getLastSyncInfo(xeroIntegrationInfo.id).subscribe((data) => {
          this.setState({ syncId: data.id });
        }, handleError),
      );
    }
  };

  setShowSyncPreview = ({ showSyncPreview, syncPreviewReadOnly = false }) => {
    this.setState({ showSyncPreview, syncPreviewReadOnly });
  };

  render() {
    const { xeroDirectConnected, syncId, xeroIntegrationInfo } = this.state;
    const { permissions } = this.props;
    const hasInvoicePermissions =
      permissions.invoices &&
      permissions.invoicesCreateEdit &&
      permissions.billingSettings;
    let descriptionText = hasInvoicePermissions
      ? "Sync your clients, service items, and invoices."
      : "Sync your clients.";

    if (!permissions.hasIntegrationsPermissions) {
      descriptionText = "Account permission needed to access this integration";
    }

    let actionButton = (
      <CpButton
        btnType="secondary"
        block
        onClick={this.connectXero}
        showLoader={this.state.fetchingConnection}
        disabled={!permissions.hasIntegrationsPermissions}
      >
        Connect
      </CpButton>
    );

    if (xeroDirectConnected) {
      actionButton = (
        <CpButton
          btnType="secondary"
          block
          onClick={this.disconnectXero}
          showLoader={this.state.fetchingConnection}
          disabled={!permissions.hasIntegrationsPermissions}
        >
          Disconnect
        </CpButton>
      );
    }

    return (
      <Scoped css={css}>
        <div className="xero-card">
          {permissions.hasIntegrationsPermissions && xeroDirectConnected && (
            <div className="client-sync-dropdown">
              <CpDropdown
                position="bottom-end"
                contentWidth={230}
                renderTrigger={({ toggle }) => (
                  <CpButton
                    icon="misc-kabob"
                    aria-label="xero-menu"
                    onClick={toggle}
                  />
                )}
                renderContent={() => (
                  <Scoped css={css}>
                    <div className="cp-select-list">
                      {xeroDirectConnected && (
                        <button
                          onClick={() =>
                            this.setShowSyncPreview({
                              showSyncPreview: true,
                              syncPreviewReadOnly: true,
                            })
                          }
                          className={maybe("disabled-dropdown-item", !syncId)}
                          disabled={!syncId}
                        >
                          <CpIcon
                            className="cp-select-list__icon-left"
                            name="misc-bar-graph"
                            fill={`var(--cp-color-app-${
                              syncId ? "primary" : "inactive"
                            }-text`}
                          />
                          View integration report
                        </button>
                      )}
                      <button
                        onClick={() =>
                          this.setShowSyncPreview({ showSyncPreview: true })
                        }
                      >
                        <CpIcon
                          className="cp-select-list__icon-left"
                          name="af-line-clockwise"
                        />
                        Restart integration
                      </button>
                      {xeroIntegrationInfo?.disconnect_error
                        ?.connection_title &&
                        permissions.hasIntegrationsPermissions && (
                          <button onClick={this.connectXero}>
                            <CpIcon
                              className="cp-select-list__icon-left"
                              name="person-key"
                            />
                            Reauthorize connection
                          </button>
                        )}
                    </div>
                  </Scoped>
                )}
              />
            </div>
          )}
          <div className="xero-logo-header">
            <img
              src={cdnImage("xero_logo_blue_circle.png")}
              height="124"
              style={{ height: "12.4rem" }}
              alt="Xero Logo"
            />
          </div>

          <div className="xero-title-text">Xero</div>
          <div className="xero-description-text">{descriptionText}</div>
          <div className="xero-connect-button">{actionButton}</div>
          {xeroIntegrationInfo?.disconnect_error?.integrations_page_error && (
            <div className="xero-card-error">
              {xeroIntegrationInfo.disconnect_error.integrations_page_error}
            </div>
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .xero-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 31.4rem;
    width: 26.8rem;
    border: 0.1rem solid var(--cp-color-app-border);
    border-radius: 0.5rem;
    position: relative;
  }

  .xero-logo-header {
    display: flex;
    width: 23.4rem;
    justify-content: space-evenly;
  }

  .client-sync-dropdown {
    height: 1.8rem;
    width: 1.8rem;
    position: absolute;
    right: 20px;
    top: 8px;
  }

  .xero-title-text {
    margin: -1.2rem auto 0;
    width: 22.6rem;
    height: 4rem;
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    font-weight: 600;
    text-align: center;
  }

  .xero-description-text {
    width: 22.6rem;
    margin-bottom: 4.4rem;
    font-size: 1.4rem !important;
    text-align: center;
  }

  .xero-connect-button {
    display: block;
    width: 23.4rem;
  }

  .upgrade-text {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 1.6rem 1.6rem 0;
    color: var(--cp-color-app-callout-text);
  }

  .disabled-dropdown-item {
    color: var(--cp-color-app-inactive-text);
  }

  .disabled-dropdown-item:hover {
    cursor: default;
    background-color: transparent;
  }

  .xero-card-error {
    color: var(--cp-color-app-warning-text);
    font-size: 1.2rem;
    font-style: italic;
    text-align: center;
    margin-top: 0.4rem;
  }
`;

import React, { useState, useEffect } from "react";
import { CpButton, CpInput, CpModal } from "canopy-styleguide!sofe";
import { useDebounceValue } from "../../use-debounce-value";
import {
  patchClient,
  validateDisplayName,
} from "../../../integrations.resource";
import { handleError } from "src/handle-error";

type Props = {
  onSubmit: (displayName?: string) => void;
  integrationId: string;
  refetchClient: () => void;
  clientId: string;
};

export function DisplayNameNone(props: Props) {
  const { onSubmit, integrationId, refetchClient, clientId } = props;
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [saving, setSaving] = useState(false);

  const debouncedDisplayName = useDebounceValue(displayName.trim(), 700);

  useEffect(() => {
    if (debouncedDisplayName?.length) {
      setLoading(true);
      const sub = validateDisplayName(
        integrationId,
        debouncedDisplayName,
        true,
      ).subscribe(
        (nextIsValid: boolean) => {
          setIsValid(nextIsValid);
          setLoading(false);
        },
        (e: Error) => {
          setLoading(false);
          handleError(e);
        },
      );
      return () => {
        setLoading(false);
        sub?.unsubscribe();
      };
    }
  }, [debouncedDisplayName]); // eslint-disable-line react-hooks/exhaustive-deps

  const onContinue = async () => {
    setSaving(true);
    try {
      await new Promise((resolve, reject) => {
        patchClient(clientId, {
          display_name: displayName.trim(),
        }).subscribe(resolve, reject);
      });
      refetchClient();
      onSubmit(displayName);
    } catch (e) {
      handleError(e as Error);
      onSubmit();
    }
  };

  const showErrorMessage =
    !loading && !isValid && !!debouncedDisplayName.trim().length;

  return (
    <>
      <CpModal.Header title="Display Name Required" />
      <CpModal.Body>
        <div className="cp-mb-16">
          This client needs a display name in order to sync to QBO. Please
          create a unique display name.
        </div>
        <div style={{ height: "4.8rem" }}>
          <CpInput
            placeholder="Enter display name"
            error={showErrorMessage && "This display name is already in use"}
            value={displayName}
            onChange={(value) => setDisplayName(value.trimStart())}
          />
        </div>
      </CpModal.Body>
      <CpModal.Footer className="flex cp-gap-8">
        <CpButton
          onClick={onContinue}
          disabled={!isValid || !displayName.length}
          showLoader={loading || saving}
        >
          Continue
        </CpButton>
        <CpButton btnType="flat" onClick={() => onSubmit()} disabled={saving}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}

import { setPublicPath } from "systemjs-webpack-interop";

setPublicPath("integrations-ui!sofe");

export const getIntegrationsComponent = () => {
  return import(
    /* webpackChunkName: "integrations" */ "src/integrations.component.js"
  ).then((mod) => mod);
};

export const getIntegrationSyncErrorBanner = () => {
  return import(
    /* webpackChunkName: "integration-sync-error-banner" */ "src/common/integration-sync-error-banner.component.js"
  ).then((mod) => mod);
};

export const getIntegrationSyncErrorModal = () => {
  return import(
    /* webpackChunkName: "integration-sync-error-modal" */ "src/common/integration-sync-error-modal.component.js"
  ).then((mod) => mod);
};

export const getManageSettingsComponent = () => {
  return import(
    /* webpackChunkName: "manage-settings" */ "src/settings/manage-settings.component"
  ).then((mod) => mod);
};

export const getIntegrationEdit = () => {
  return import(
    /* webpackChunkName: "integration-edit" */ "src/common/integration-edit/integration-edit.component"
  ).then((mod) => ({ default: mod.IntegrationEdit }));
};

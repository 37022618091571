import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import { commonCss } from "../sync-preview.styles";
import { getClientHeaderRow } from "../sync-preview.helpers";
import { CanopyClientRow, QboClientRow } from "./client-row.component";

export default function ClientDuplicatesSection({
  duplicates,
  readOnly,
  newCrmEnabled,
}) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell className="section-instructions">
        <div className="instructions-description">
          <div className="cp-flex-spread-center">
            <div className="cp-title cp-wt-semibold">Duplicate Clients</div>
            <a
              href="https://support.getcanopy.com/hc/en-us/articles/11126044366875-Resolve-Duplicate-and-Unmatched-Clients"
              rel="noreferrer"
              target="_blank"
              className="cp-mr-16"
            >
              Learn how to fix duplicates
            </a>
          </div>
          <div className="cp-pv-8">
            <p>
              Duplicates will not sync. Adjust the display names in Canopy to
              match the QBO display name then restart your sync and refresh the
              list.
            </p>
            {!readOnly && (
              <p
                className="cp-color-app-warning-text"
                style={{ marginTop: "-10px" }}
              >
                Duplicate clients will not sync.
              </p>
            )}
          </div>
        </div>
      </CpWell>

      <div className="clients-display">
        {!isEmpty(duplicates) &&
          duplicates.map((duplicateObj, i) => {
            const canopyDuplicates = duplicateObj.canopy;
            const qboClientDuplicates = duplicateObj?.third_party;

            return (
              <table key={`duplicates-${i}`} className="client-table">
                {getClientHeaderRow(newCrmEnabled)}
                <tbody>
                  {canopyDuplicates
                    ?.filter((client) => !!client)
                    .map((canopyClient, idx) => (
                      <CanopyClientRow
                        key={`canopy-duplicate-client-${canopyClient.id}-${idx}`}
                        canopyClient={canopyClient}
                        newCrmEnabled={newCrmEnabled}
                      />
                    ))}
                  {qboClientDuplicates
                    ?.filter((client) => !!client)
                    .map((qboClient, idx) => (
                      <QboClientRow
                        key={`qbo-duplicate-${qboClient.third_party_client_id}-${idx}`}
                        qboClient={qboClient}
                        newCrmEnabled={newCrmEnabled}
                      />
                    ))}
                </tbody>
              </table>
            );
          })}
      </div>
    </div>
  );
}

import React from "react";
import { CpTable } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";

import {
  getSettingsListSchema,
  sectionMap,
  sectionList,
} from "./settings.helper";
import type {
  IntegrationInfo,
  SyncStatusProps,
} from "../types/integration.d.ts";
import type { User } from "../types/user.d.ts";

export default function SyncSettingsList({
  integrationInfo,
  syncId,
  updatePaymentsSync,
  startSync,
  loading,
  user,
}: {
  integrationInfo: IntegrationInfo;
  syncId: string;
  updatePaymentsSync: ({ enabled }: { enabled: boolean }) => void;
  startSync: (section: string) => void;
  loading: boolean;
  user: User;
}) {
  const hasClientsPermissions = useHasAccess(
    ["clients_not_assigned", "clients_create_edit"],
    true,
  );
  const hasInvoicePermissions = useHasAccess(
    [
      "billing_invoices",
      "billing_invoices_create_edit",
      "company_settings_billing",
    ],
    true,
  );
  const hasPaymentsPermissions = useHasAccess(
    ["billing_payments", "billing_payments_create_edit"],
    true,
  );

  const permissionsMap = {
    clients: hasClientsPermissions,
    items: hasInvoicePermissions,
    invoices: hasInvoicePermissions,
    payments: hasPaymentsPermissions,
  };

  const getStatus = (section: SyncStatusProps, autoApplyCredit: boolean) => {
    if (!!section?.first_synced_at && !section.synced_at && autoApplyCredit) {
      return "error";
    } else if (section.first_synced_at && !section.synced_at) {
      return "paused";
    } else if (section.synced_at) {
      return "syncing";
    } else {
      return "notStarted";
    }
  };

  const getSyncDisabled = (section: string) => {
    if (
      section === "items" &&
      !integrationInfo?.clients.sync_status?.first_synced_at
    ) {
      return true;
    } else if (
      section === "invoices" &&
      !integrationInfo?.items?.sync_status?.first_synced_at
    ) {
      return true;
    } else if (
      section === "payments" &&
      !integrationInfo?.invoices?.sync_status?.first_synced_at
    ) {
      return true;
    }

    return false;
  };

  const formattedSyncStatuses = sectionList
    .filter(
      (statusKey) =>
        typeof integrationInfo?.[statusKey].sync_status === "object" &&
        integrationInfo?.[statusKey].sync_status !== null,
    )
    .map((section) => ({
      ...integrationInfo?.[section]?.sync_status,
      count: integrationInfo?.[section]?.sync_status?.first_synced_at
        ? integrationInfo?.[section]?.sync_status?.count
        : null,
      id: section,
      sectionName: sectionMap()[
        section as keyof typeof sectionMap
      ],
      status: getStatus(
        integrationInfo?.[section]?.sync_status,
        !!integrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit &&
          section === "payments",
      ),
      syncId,
      updatePaymentsSync,
      hasPermissions: permissionsMap[section as keyof typeof permissionsMap],
      startSync,
      syncDisabled: getSyncDisabled(section),
      showLoader: loading,
      user,
    }));

  const schema = CpTable.useSchema(
    () => getSettingsListSchema(),
    [formattedSyncStatuses],
  );

  return (
    <div>
      <CpTable
        className="manage-settings-list"
        data={formattedSyncStatuses}
        schema={schema}
        columnOrder={["sectionName", "count", "actions"]}
        columnWidths={{
          sectionName: "lg",
          count: 40,
          actions: "md",
        }}
        fullWidth
      />
    </div>
  );
}

import React from "react";
import Disposable from "react-disposable-decorator";
import { Scoped, k } from "kremling";
import { CpOverlay, CpLoader, CpEmptyState } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";

import { getLastSuccessfulSync } from "../../integrations.resource";
import NewServiceItemSection from "./sections/service-items-new-section.component";
import ServiceItemMatchesSection from "./sections/service-items-matches-section.component";
import ServiceItemUnmatchedSection from "./sections/service-items-unmatched-section.component";
import ClientDuplicatesSection from "./sections/clients-duplicates-section.component";
import ClientUnmatchedCanopySection from "./sections/clients-unmatched-canopy-section.component";
import ClientUnmatchedThirdPartySection from "./sections/clients-unmatched-third-party-section.component";
import ClientMatchesSection from "./sections/clients-matches-section.component";
import NewClientsSection from "./sections/clients-new-section.component";
import PreviewNav from "./preview-nav.component";
import {
  findSectionsWithData,
  getActiveSection,
  sections,
  getPaginator,
} from "./sync-preview.helpers";

@Disposable
export default class IntegrationsReport extends React.Component {
  state = {
    activeSection: sections.DUPLICATES,
    loading: false,
    itemsPreviewData: {
      matches: [],
      newServiceItems: [],
      unmatchedCanopy: [],
    },
    clientPreviewData: {
      duplicates: [],
      inactiveClientsCount: 0,
      matches: [],
      newClients: [],
      unmatchedCanopy: [],
      unmatchedThirdParty: [],
    },
    sectionsWithData: [],
    lastSync: { date: "", time: "" },
    paginatedData: [],
  };

  componentDidMount() {
    this.getLastSuccessfulSyncData();
  }

  getLastSuccessfulSyncData = () => {
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      getLastSuccessfulSync(
        this.props.qboIntegrationInfo.id,
        this.props.clientSyncId,
      ).subscribe(({ clients, finished_at, items }) => {
        const clientPreviewData = {
          duplicates: clients?.duplicates,
          unmatchedCanopy: clients?.unmatched_canopy,
          unmatchedThirdParty: clients?.unmatched_third_party,
          matches: clients?.matched,
          newClients: clients?.canopy_created,
        };

        const { activeSection, sectionsWithData } =
          findSectionsWithData(clientPreviewData);

        const itemsPreviewData = {
          unmatchedCanopy: items?.unmatched_canopy,
          matches: items?.matched,
          newServiceItems: items?.canopy_created,
        };

        this.setState({
          lastSync: {
            date: DateTime.fromISO(finished_at).toFormat("DD"),
            time: DateTime.fromISO(finished_at).toFormat("t"),
          },
          clientPreviewData,
          loading: false,
          activeSection,
          sectionsWithData,
          itemsPreviewData,
        });
      }, handleError),
    );
  };

  setActiveSection = (activeSection) => {
    if (activeSection === this.state.activeSection) {
      return;
    }

    this.setState({
      activeSection,
      paginatedData: [],
    });
  };

  render() {
    const {
      activeSection,
      loading,
      lastSync,
      clientPreviewData,
      sectionsWithData,
      itemsPreviewData,
      paginatedData,
    } = this.state;
    const {
      showServiceItemsPreview,
      showClientsPreview,
      setShowClientsPreview,
      setShowServiceItemsPreview,
    } = this.props;

    const showEmptyState = isEmpty(sectionsWithData);
    const {
      unmatchedCanopyInView,
      matchesInView,
      newServiceItemsInView,
      duplicatesInView,
      newClientsInView,
      unmatchedThirdPartyInView,
    } = getActiveSection(activeSection);
    const serviceItemsData =
      itemsPreviewData.unmatchedCanopy ||
      itemsPreviewData.matches ||
      itemsPreviewData.newServiceItems;

    return (
      <>
        <CpOverlay.Header title="Integration Report">
          <Scoped css={css}>
            {!showEmptyState && (
              <div className="last-sync-date">
                Last sync: {lastSync.date} | {lastSync.time}
              </div>
            )}
          </Scoped>
        </CpOverlay.Header>
        <CpOverlay.Body>
          <Scoped css={css}>
            {!loading && !showEmptyState ? (
              <div className="sync-preview">
                <div className="left-nav-container">
                  <PreviewNav
                    syncPreviewData={clientPreviewData}
                    unmatchedCanopyInView={unmatchedCanopyInView}
                    unmatchedThirdPartyInView={unmatchedThirdPartyInView}
                    newClientsInView={newClientsInView}
                    matchesInView={matchesInView}
                    duplicatesInView={duplicatesInView}
                    setActiveSection={this.setActiveSection}
                    headerText="QBO Clients Sync Report"
                    isActiveBox={showClientsPreview}
                    readOnly
                    setActiveBox={(activeSection) => {
                      const { sectionsWithData } =
                        findSectionsWithData(clientPreviewData);
                      this.setState({
                        activeSection,
                        sectionsWithData,
                        paginatedData: [],
                      });
                      setShowClientsPreview(true);
                      setShowServiceItemsPreview(false);
                    }}
                    isClients
                  />
                  {serviceItemsData && (
                    <PreviewNav
                      syncPreviewData={itemsPreviewData}
                      unmatchedCanopyInView={unmatchedCanopyInView}
                      matchesInView={matchesInView}
                      newServiceItemsInView={newServiceItemsInView}
                      setActiveSection={this.setActiveSection}
                      headerText="QBO Service Items Report"
                      isActiveBox={showServiceItemsPreview}
                      readOnly
                      setActiveBox={(activeSection) => {
                        const { sectionsWithData } =
                          findSectionsWithData(itemsPreviewData);
                        this.setState({
                          activeSection,
                          sectionsWithData,
                          paginatedData: [],
                        });
                        setShowClientsPreview(false);
                        setShowServiceItemsPreview(true);
                      }}
                    />
                  )}
                </div>
                <div className="right-side-container">
                  {showServiceItemsPreview ? (
                    <>
                      {unmatchedCanopyInView && (
                        <ServiceItemUnmatchedSection
                          unmatchedCanopy={paginatedData}
                          readOnly
                        />
                      )}
                      {matchesInView && (
                        <ServiceItemMatchesSection
                          matches={paginatedData}
                          readOnly
                        />
                      )}
                      {newServiceItemsInView && (
                        <NewServiceItemSection
                          newServiceItems={paginatedData}
                          readOnly
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {duplicatesInView && (
                        <ClientDuplicatesSection
                          duplicates={paginatedData}
                          readOnly
                          newCrmEnabled={this.props.newCrmEnabled}
                        />
                      )}
                      {unmatchedCanopyInView && (
                        <ClientUnmatchedCanopySection
                          unmatchedCanopyClients={paginatedData}
                          readOnly
                          newCrmEnabled={this.props.newCrmEnabled}
                        />
                      )}
                      {matchesInView && (
                        <ClientMatchesSection
                          matches={paginatedData}
                          readOnly
                          newCrmEnabled={this.props.newCrmEnabled}
                        />
                      )}
                      {newClientsInView && (
                        <NewClientsSection
                          newClients={paginatedData}
                          readOnly
                          newCrmEnabled={this.props.newCrmEnabled}
                        />
                      )}
                      {unmatchedThirdPartyInView && (
                        <ClientUnmatchedThirdPartySection
                          unmatchedThirdPartyClients={paginatedData}
                          newCrmEnabled={this.props.newCrmEnabled}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : showEmptyState && !loading ? (
              <div className="sync-preview">
                <CpEmptyState
                  img="es_tasks_list"
                  text="No sync data is available for review."
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <CpLoader />
              </div>
            )}
          </Scoped>
        </CpOverlay.Body>
        <div
          style={{
            marginBottom: "1.6rem",
            marginTop: "0.8rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {getPaginator({
            sectionsInView: {
              duplicatesInView,
              unmatchedCanopyInView,
              unmatchedThirdPartyInView,
              matchesInView,
              newClientsInView,
              newServiceItemsInView,
            },
            setPaginatedData: (paginatedData) =>
              this.setState({ paginatedData }),
            previewData: showClientsPreview
              ? clientPreviewData
              : itemsPreviewData,
          })}
        </div>
      </>
    );
  }
}

const css = k`
  .sync-preview {
    display: flex;
  }

  .right-side-container {
    margin-left: 28.8rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .left-nav-container {
    margin-left: 1.6rem;
    position: fixed;
  }

  .last-sync-date {
    font-style: italic;
    color: var(--cp-color-app-secondary-text);
  }
`;

import React, { useMemo } from "react";
import {
  CpIcon,
  CpNameChip,
  CpButton,
  phoneNumber,
} from "canopy-styleguide!sofe";
import { Client } from "../../../types/client";

type Props = {
  client: Client;
  clientQboData?: Client;
  newCrmEnabled: boolean;
  showEditContactModal: () => void;
};

export function RowClient(props: Props) {
  const { client, clientQboData, newCrmEnabled, showEditContactModal } = props;

  const clientPrimaryPhone = useMemo(() => {
    return (
      (newCrmEnabled
        ? clientQboData?.phone
        : client?.phones?.find((phone) => phone.is_primary)?.value) || ""
    );
  }, [clientQboData?.phone, client?.phones, newCrmEnabled]);

  const clientPrimaryEmail = useMemo(() => {
    return (
      (newCrmEnabled
        ? clientQboData?.email
        : client?.emails?.find((email) => email.is_primary)?.value) || ""
    );
  }, [clientQboData?.email, client?.emails, newCrmEnabled]);

  // remove when newCrmEnabled is removed
  const clientPrimaryAddress = useMemo(
    () => client?.addresses?.find((address) => address.is_primary),
    [client?.addresses],
  );

  return (
    <tr>
      <td className="">
        <div className="flex items-center cp-gap-16">
          <CpNameChip initials={client.initials} />{" "}
          {newCrmEnabled
            ? client.display_name
            : client.display_name || client.name}
        </div>
      </td>
      <td className="cp-text-center">
        <CpIcon name="misc-canopy-mark" size={20} />
      </td>
      {newCrmEnabled && (
        <td>
          {client.is_business ? (
            client.business_name
          ) : (
            <div className="cp-flex-center">
              {clientQboData?.name}
              <CpButton
                className="cp-ml-8"
                icon="crud-pencil"
                aria-label="edit"
                onClick={showEditContactModal}
              />
            </div>
          )}
        </td>
      )}
      <td>{phoneNumber(clientPrimaryPhone) || "-"}</td>
      <td>{clientPrimaryEmail || "-"}</td>
      {newCrmEnabled ? (
        <>
          <td>{clientQboData?.street_address || "-"}</td>
          <td>{clientQboData?.locality || "-"}</td>
          <td>{clientQboData?.region || "-"}</td>
        </>
      ) : (
        <>
          <td>
            <div>{clientPrimaryAddress?.value?.address_1 || "-"}</div>
            {!!clientPrimaryAddress?.value?.address_1 &&
              !!clientPrimaryAddress?.value?.address_2 && (
                <div>{clientPrimaryAddress?.value?.address_2}</div>
              )}
          </td>
          <td>{clientPrimaryAddress?.value?.locality || "-"}</td>
          <td>{clientPrimaryAddress?.value?.region || "-"}</td>
        </>
      )}
    </tr>
  );
}

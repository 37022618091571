import React from "react";
import { CpNameChip, phoneNumber } from "canopy-styleguide!sofe";
import { QboIcon } from "../common/qbo-icon.component";
import { ThirdPartyEntity } from "../../../types/client";
import { thirdPartyUrl } from "../common/third-party-url";

type Props = {
  thirdPartyEntity?: ThirdPartyEntity;
  newCrmEnabled?: boolean;
};

export function RowMatched(props: Props) {
  const { thirdPartyEntity, newCrmEnabled } = props;

  const user = thirdPartyEntity;
  const phone = newCrmEnabled ? user?.phone : user?.phones?.[0]?.value;
  return (
    <tr>
      <td>
        <div className="flex items-center cp-gap-16">
          <CpNameChip name={user?.display_name || ""} />{" "}
          <a
            href={thirdPartyUrl(user?.third_party_client_id)}
            target="_blank"
            rel="noreferrer"
          >
            {user?.display_name}
          </a>
        </div>
      </td>
      <td className="cp-text-center">
        <QboIcon />
      </td>
      {newCrmEnabled && <td>{user?.name}</td>}
      <td>{phone ? phoneNumber(phone) : "-"}</td>
      <td>{(newCrmEnabled ? user?.email : user?.emails?.[0]?.value) || "-"}</td>
      <td>
        {newCrmEnabled ? (
          user?.street_address || "-"
        ) : (
          <>
            <div>{user?.addresses?.[0]?.value?.address_1 || "-"}</div>
            {user?.addresses?.[0]?.value?.address_1 &&
              user?.addresses?.[0]?.value?.address_2 && (
                <div>{user?.addresses?.[0]?.value?.address_2}</div>
              )}
          </>
        )}
      </td>
      <td>
        {(newCrmEnabled
          ? user?.locality
          : user?.addresses?.[0]?.value?.locality) || "-"}
      </td>
      <td>
        {(newCrmEnabled ? user?.region : user?.addresses?.[0]?.value?.region) ||
          "-"}
      </td>
    </tr>
  );
}

export const providers = {
  gmail: {
    icon: "https://cdn.canopytax.com/static/communications-ui/gmail_icon.svg",
    header: "Gmail Account",
    description:
      "Connect your Google account to view and manage your email and calendar events.",
  },
  outlook: {
    icon: "https://cdn.canopytax.com/static/communications-ui/office_365_icon.svg",
    header: "Microsoft 365",
    description:
      "Connect your Microsoft 365 account to view and manage your associated emails and calendar events.",
  },
  other: {
    icon: "https://cdn.canopytax.com/static/communications-ui/email_circle_gray_icon.svg",
    header: "Email Account",
    description:
      "Connect any IMAP or SMTP email account to view and manage associated emails in the global inbox.",
  },
};

import React, { useEffect, useRef, useState, useMemo } from "react";
import { useCss, k, a } from "kremling";
import {
  CpButton,
  CpLoader,
  CpOverlay,
  CpInlineNotification,
  modalService,
} from "canopy-styleguide!sofe";
import { onPusher } from "fetcher!sofe";
import { isUndefined, noop } from "lodash";
import { IntegrationInfo } from "../../types/integration";
import {
  Client,
  ConnectedQBOData,
  Contact,
  ThirdPartyEntity,
} from "../../types/client";
import {
  qboMatchNew,
  getQboMatch,
  qboUnmatch,
  validateDisplayName,
  qboMatch,
  clientDisplayNameAvailable,
  patchClient,
  getAvailableContactsForClient,
} from "../../integrations.resource";
import { RowClient } from "./table/row-client.component";
import { RowMatched } from "./table/row-matched.component";
import { RowNoMatch } from "./table/row-no-match.component";
import { RowLoading } from "./table/row-loading.component";
import { RowSearchClients } from "./table/row-search-clients.component";
import { infoToast } from "toast-service!sofe";
import { DisplayNameConflict } from "./common/display-name-conflict.component";
import { DisplayNameNone } from "./common/display-name-none.component";
import { Subscription } from "rxjs";
import { handleError } from "../../handle-error";
import { MatchSyncedClientModal } from "./common/match-synced-client-modal.component";
import { SelectContactModal } from "./common/select-contact-modal.component";
import { useCrmHierarchy } from "../use-crm-hierarchy.helper";
import { TAvailableContact, TContact } from "../../types/contact";

type Props = {
  show: boolean;
  onClose: () => void;
  integration: IntegrationInfo;
  client: Client;
  refetchClient?: () => void;
  qboRefreshTick: number;
  qboRefresh: () => void;
  defaultSearchExisting?: boolean;
  contact?: TContact;
};

export type IntegrationEditStep =
  | "init"
  | "connected"
  | "matching"
  | "unmatching"
  | "disconnected"
  | "searchExisting"
  | "reviewMatch"
  | "syncing";

export function IntegrationEdit(props: Props) {
  const {
    show,
    onClose,
    integration,
    client,
    refetchClient = noop,
    qboRefreshTick,
    qboRefresh = noop,
    defaultSearchExisting = false,
    contact,
  } = props;
  const scope = useCss(css);
  const [connectedData, setConnectedData] = useState<ConnectedQBOData>();
  const [clientQboData, setClientQboData] = useState<Client>();
  const prevConnectedDataRef = useRef<ConnectedQBOData>();
  const [step, setStep] = useState<IntegrationEditStep>("init");
  const [availableContacts, setAvailableContacts] = useState<
    TAvailableContact[]
  >([]);
  const [selectedQboClient, setSelectedQboClient] =
    useState<ThirdPartyEntity>();
  const [selectedQboContact, setSelectedQboContact] = useState<
    TContact | Contact | undefined
  >();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showMatchSyncedClientModal, setShowMatchSyncedClientModal] =
    useState(false);
  const [showSelectContactModal, setShowSelectContactModal] = useState(false);
  const qboMatchRef = useRef<Subscription>();
  const newCrmEnabled = useCrmHierarchy();

  const connected = step === "connected";
  const matching = step === "matching";
  const unmatching = step === "unmatching";
  const disconnected = step === "disconnected";
  const searchExisting = step === "searchExisting";
  const reviewMatch = step === "reviewMatch";
  const syncing = step === "syncing";

  const discontinuedMessage = disconnected || matching;
  const connectedMessage = connected || unmatching;
  const searchingMessage = searchExisting;
  const syncMessage = reviewMatch || syncing;

  useEffect(() => {
    return () => {
      qboMatchRef.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sub = onPusher("third_party_client").subscribe((res: any) => {
      if (client.id === res?.canopy_client_id && res.third_party_url) {
        qboRefresh();
      }
    }, handleError);
    return () => {
      sub?.unsubscribe?.();
    };
  }, [client.id, qboRefresh]);

  useEffect(() => {
    if (show) {
      const sub = getQboMatch(
        integration.id,
        client.id,
        selectedQboContact?.id,
      ).subscribe(
        (res: {
          canopy?: Client;
          match?: ConnectedQBOData;
          third_party?: ConnectedQBOData;
        }) => {
          setConnectedData(newCrmEnabled ? res.third_party : res.match);
          setClientQboData(res.canopy);
          const hasPrevConnectedData = !isUndefined(
            prevConnectedDataRef.current,
          );
          if ((res.match || res.third_party) && show) {
            setStep("connected");
            if (hasPrevConnectedData && !prevConnectedDataRef.current) {
              infoToast({ message: successMessage });
            }
          } else if (defaultSearchExisting) {
            setStep("searchExisting");
          } else {
            setStep("disconnected");
          }
        },
      );

      prevConnectedDataRef.current = connectedData;
      return () => {
        sub.unsubscribe();
      };
    }
  }, [integration.id, client.id, selectedQboContact?.id, qboRefreshTick, show]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!show) return;

    const sub = getAvailableContactsForClient(
      integration.id,
      client.id,
      integration.type,
    ).subscribe(setAvailableContacts);
    return () => {
      sub.unsubscribe();
    };
  }, [show, integration.id, client.id, integration.type]);

  useEffect(() => {
    if (!newCrmEnabled) setSelectedQboContact(undefined);

    if (contact) {
      setSelectedQboContact(contact);
    } else if (client?.canopy_contact_id_for_third_party) {
      setSelectedQboContact(
        client?.contacts?.find(
          (contact) => contact.id === client.canopy_contact_id_for_third_party,
        ),
      );
    } else {
      setSelectedQboContact(
        client?.contacts?.find((contact) => contact.is_primary),
      );
    }
  }, [
    client.canopy_contact_id_for_third_party,
    client?.contacts,
    contact,
    newCrmEnabled,
  ]);

  const onMatch = async (thirdPartyClientId: string) => {
    setStep("matching");
    qboMatchRef.current = qboMatch(
      integration.id,
      client.id,
      thirdPartyClientId,
      client.is_business ? "" : selectedQboContact?.id,
    ).subscribe(
      () => {},
      (e: Error) => {
        handleError(e);
        setStep("disconnected");
      },
    );
  };

  const needNewDisplayName = async () => {
    return await modalService.render(DisplayNameNone, {
      integrationId: integration.id,
      refetchClient,
      clientId: client.id,
    });
  };

  const onExistingMatch = async () => {
    setStep("syncing");
    try {
      if (
        client.display_name &&
        selectedQboClient?.display_name &&
        client.display_name === selectedQboClient?.display_name
      ) {
        // skip all these checks and match them
      } else if (!client.display_name && selectedQboClient?.display_name) {
        const available = await new Promise((resolve, reject) => {
          clientDisplayNameAvailable(selectedQboClient.display_name).subscribe(
            resolve,
            reject,
          );
        });
        if (available) {
          await new Promise((resolve, reject) => {
            patchClient(client.id, {
              display_name: selectedQboClient.display_name,
            }).subscribe(resolve, reject);
          });
          refetchClient();
        } else {
          const createdDisplayName = await needNewDisplayName();
          if (!createdDisplayName) {
            setStep("reviewMatch");
            return;
          }
        }
      } else if (!client.display_name) {
        const createdDisplayName = await needNewDisplayName();
        if (!createdDisplayName) {
          setStep("reviewMatch");
          return;
        }
      } else {
        const response = await new Promise((resolve, reject) => {
          validateDisplayName(
            integration.id,
            client.display_name,
            false,
          ).subscribe(resolve, reject);
        });

        if (response === false) {
          const done = await modalService.render(DisplayNameConflict, {
            displayName: client.display_name,
            integrationId: integration.id,
            clientId: client.id,
            refetchClient,
          });

          if (!done) {
            setStep("reviewMatch");
            return;
          }
          refetchClient();
        }
      }

      setSuccessMessage("1 client has successfully synced in QBO");
      await new Promise((resolve, reject) => {
        qboMatch(
          integration.id,
          client.id,
          selectedQboClient?.third_party_client_id,
          client.is_business ? "" : selectedQboContact?.id,
        ).subscribe((res: { success: boolean }) => {
          resolve(res);
          newCrmEnabled && qboRefresh();
          refetchClient();
        }, reject);
      });
    } catch (e) {
      setStep("reviewMatch");
      handleError(e as Error);
    }
  };

  const onRematch = async (qboContact: Contact) => {
    setStep("syncing");
    try {
      setSuccessMessage("1 client has successfully synced in QBO");
      await new Promise((resolve, reject) => {
        qboMatch(
          integration.id,
          client.id,
          client?.third_party_id,
          client.is_business ? "" : qboContact?.id,
        ).subscribe((res: { success: boolean }) => {
          resolve(res);
          newCrmEnabled && qboRefresh();
          refetchClient();
        }, reject);
      });
    } catch (e) {
      setStep("reviewMatch");
      handleError(e as Error);
    }
  };

  const onCreateNew = async () => {
    setStep("matching");
    try {
      if (!client.display_name) {
        const createdDisplayName = await modalService.render(DisplayNameNone, {
          integrationId: integration.id,
          refetchClient,
          clientId: client.id,
        });
        if (!createdDisplayName) {
          setStep("disconnected");
          return;
        }
      } else {
        const response = await new Promise((resolve, reject) => {
          validateDisplayName(
            integration.id,
            client.display_name,
            false,
          ).subscribe(resolve, reject);
        });

        if (response === false) {
          const done = await modalService.render(DisplayNameConflict, {
            displayName: client.display_name,
            integrationId: integration.id,
            clientId: client.id,
            refetchClient,
          });

          if (!done) {
            setStep("disconnected");
            return;
          }
          refetchClient();
        }
      }

      setSuccessMessage("1 client has been created in QBO and is now synced");
      await new Promise((resolve, reject) => {
        qboMatchNew(
          integration.id,
          client.id,
          client.is_business ? "" : selectedQboContact?.id,
        ).subscribe((res: { success: boolean }) => {
          resolve(res);
          newCrmEnabled && qboRefresh();
          refetchClient();
        }, reject);
      });
    } catch (e) {
      setStep("disconnected");
      handleError(e as Error);
    }
  };

  const onSearchExisting = async () => {
    setStep("searchExisting");
  };

  const onUnmatch = async () => {
    setStep("unmatching");
    const prevThirdPartyClientId = connectedData?.third_party_client_id;
    qboUnmatch(integration.id, client.id).subscribe(
      () => {
        setStep("disconnected");
        infoToast({
          message:
            "1 Client has been unmatched and disabled from syncing in QBO",
          actionText: "Undo",
          actionCallback: () => {
            if (prevThirdPartyClientId) {
              setSuccessMessage("Successfully rematched");
              onMatch(prevThirdPartyClientId);
            }
          },
        });
        qboRefresh();
      },
      (e: Error) => {
        setStep("connected");
        handleError(e);
      },
    );
  };

  const contactAlreadySynced = useMemo(() => {
    if (!newCrmEnabled || client.is_business) return false;
    const selectedQboClient = availableContacts?.find(
      (contact) => contact.id === selectedQboContact?.id,
    );

    if (!selectedQboClient?.is_available) return true;

    return false;
  }, [newCrmEnabled, client, availableContacts, selectedQboContact]);

  const updateSelectedContact = (contact: TContact) => {
    const qboContact = client?.contacts?.find((c) => c.id === contact.id);
    if (qboContact) {
      setSelectedQboContact(qboContact);
      if (connected) {
        onRematch(qboContact);
      }
    }
  };

  return (
    <CpOverlay show={show} onClose={onClose}>
      {step === "init" ? (
        <div
          className="flex items-center justify-center"
          style={{ height: "100%" }}
        >
          <CpLoader size="lg" />
        </div>
      ) : (
        <>
          <CpOverlay.Header
            title={
              <>
                {(connectedMessage || discontinuedMessage) &&
                  "QBO Synced clients"}
                {searchingMessage && "Select a QBO Match"}
                {syncMessage && "Sync with QBO Match"}
              </>
            }
          >
            <div className="flex cp-gap-16">
              {searchExisting && (
                <>
                  <CpButton
                    btnType="secondary"
                    onClick={() => setStep("disconnected")}
                  >
                    Back
                  </CpButton>
                  <CpButton
                    btnType="primary"
                    onClick={() => {
                      if (selectedQboClient?.canopy_client_id) {
                        setShowMatchSyncedClientModal(true);
                      } else {
                        setStep("reviewMatch");
                      }
                    }}
                    disabled={!selectedQboClient}
                  >
                    Match
                  </CpButton>
                </>
              )}
              {(reviewMatch || syncing) && (
                <>
                  <CpButton
                    btnType="secondary"
                    onClick={() => setStep("searchExisting")}
                    disabled={syncing}
                  >
                    Back
                  </CpButton>
                  <CpButton
                    btnType="primary"
                    onClick={() => {
                      onExistingMatch();
                    }}
                    showLoader={syncing}
                  >
                    Sync
                  </CpButton>
                </>
              )}
              {!searchExisting && !reviewMatch && !syncing && (
                <>
                  <CpButton
                    btnType="secondary"
                    onClick={onClose}
                    disabled={unmatching || matching}
                  >
                    Cancel
                  </CpButton>
                  <CpButton
                    btnType="primary"
                    onClick={onClose}
                    disabled={matching}
                  >
                    Done
                  </CpButton>
                </>
              )}
            </div>
          </CpOverlay.Header>
          <CpOverlay.Body>
            <div {...scope} className="ie-container">
              {contactAlreadySynced && selectedQboContact && (
                <div className="cp-mb-16">
                  <CpInlineNotification
                    type="error"
                    message={`The following client, [${selectedQboContact.name}], is displaying a contact this is already associated with another synced Canopy client.  Please edit the contact selection in order to sync the client to QBO.`}
                  />
                </div>
              )}
              <div className="ie-status">
                <div>
                  {discontinuedMessage &&
                    "Your Canopy client needs a QBO match in order to resume syncing"}
                  {searchingMessage &&
                    "Select an existing QBO customer to match with your Canopy client. If you need to refine your search, use the matching criteria or search bar below."}
                  {syncMessage &&
                    "You’re almost done. Carefully review the selected match. Clicking “sync” will cause the Canopy client info to overwrite the client info in QBO."}
                </div>
                <div className="ie-status__indication">
                  {!searchExisting && (
                    <>
                      <div
                        className={a("ie-status__circle").m(
                          "--synced",
                          connected,
                        )}
                      />
                      {connected ? "Synced" : "Not connected"}
                    </>
                  )}
                </div>
              </div>
              <div className="ie-table">
                <table>
                  <thead>
                    <tr>
                      <td style={{ width: "20%" }} colSpan={2}>
                        Client name
                      </td>
                      {newCrmEnabled && (
                        <td>
                          {client.is_business ? "Business Name" : "Contact"}
                        </td>
                      )}
                      <td>Phone number</td>
                      <td>Email</td>
                      <td>Street address</td>
                      <td>City</td>
                      <td>State</td>
                    </tr>
                  </thead>
                  <tbody>
                    <RowClient
                      client={client}
                      clientQboData={clientQboData}
                      newCrmEnabled={newCrmEnabled}
                      showEditContactModal={() =>
                        setShowSelectContactModal(true)
                      }
                    />
                    {reviewMatch && (
                      <RowMatched
                        thirdPartyEntity={selectedQboClient}
                        newCrmEnabled={newCrmEnabled}
                      />
                    )}
                    {(connected || unmatching) && (
                      <>
                        <RowMatched
                          // @ts-expect-error: after crm is released we wont have weird data disparities like this
                          thirdPartyEntity={
                            newCrmEnabled
                              ? connectedData
                              : connectedData?.third_party_entity
                          }
                          newCrmEnabled={newCrmEnabled}
                        />
                        <tr>
                          <td
                            colSpan={8}
                            className="cp-text-right cp-pr-16"
                            style={{ height: 65 }}
                          >
                            <CpButton
                              icon="broken-link"
                              aria-label="Unmatch"
                              btnType="secondary"
                              showLoader={unmatching}
                              onClick={onUnmatch}
                            >
                              Unmatch
                            </CpButton>
                          </td>
                        </tr>
                      </>
                    )}
                    {(disconnected || matching) && (
                      <RowNoMatch
                        creatingNew={matching}
                        onCreateNew={onCreateNew}
                        onSearchExisting={onSearchExisting}
                        defaultSearchExisting={defaultSearchExisting}
                        disabled={contactAlreadySynced}
                      />
                    )}
                    {syncing && <RowLoading newCrmEnabled={newCrmEnabled} />}
                  </tbody>
                </table>
              </div>
              {searchExisting && (
                <RowSearchClients
                  integration={integration}
                  setSelectedQboClient={setSelectedQboClient}
                  selectedQboClient={selectedQboClient}
                  client={client}
                  newCrmEnabled={newCrmEnabled}
                />
              )}
              <MatchSyncedClientModal
                show={showMatchSyncedClientModal}
                name={client?.display_name}
                close={() => setShowMatchSyncedClientModal(false)}
                match={() => setStep("reviewMatch")}
              />
              {newCrmEnabled && (
                <SelectContactModal
                  show={showSelectContactModal}
                  onSave={updateSelectedContact}
                  onClose={() => setShowSelectContactModal(false)}
                  client={client}
                  currentContact={selectedQboContact}
                  availableContacts={availableContacts}
                />
              )}
            </div>
          </CpOverlay.Body>
        </>
      )}
    </CpOverlay>
  );
}

const css = k`
  .ie-container {

  }

  .ie-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.2rem;
    padding-right: 2rem;
    margin-bottom: 1.6rem;
  }

  .ie-status__indication {
    display: flex;
    align-items: center;
    gap: .8rem;
  }

  .ie-status__circle {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 999rem;
    background-color: var(--cp-color-app-icon);

    &.--synced {
      background-color: var(--cp-color-app-success-text);
    }
  }

  .ie-table {
    border: solid .1rem var(--cp-color-app-border);
    border-radius: .8rem;
    overflow: auto;
    margin-bottom: 3.2rem;

    .ie-table-header {
      display: flex;
      border-bottom: solid .1rem var(--cp-color-app-border);
      background-color: var(--cp-color-well-l2-bg);
      font-weight: 600;
      padding: 0 1.6rem;
    }

    .ie-table-body {
      display: flex;
      padding: 1.6rem;
    }

    .ie-table-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.6rem;
      border: solid .1rem var(--cp-color-app-border);
      width: 100%;
      border-radius: .8rem;

      > * {
        width: 25%;
        display: flex;
        align-items: center;
      }
    }

    .ie-table-row--hidden {
      border-color: transparent;
    }


    .ie-table-cell-1 {
      width: 10%;
    }
    .ie-table-cell-2 {
      width: 20%;
    }
    .ie-table-cell-3 {
      width: 30%;
    }
    .ie-table-cell-4 {
      width: 40%;
    }

    table {
      border-collapse: collapse;
      width: 100%;

      thead tr {
        td {
          border-bottom: solid .1rem var(--cp-color-app-border);
          padding: 1.6rem 0 1.6rem 1.6rem;
          font-weight: 600;
          background-color: var(--cp-color-well-l2-bg);
        }

        & > td:last-child {
          padding-right: 1.6rem;
        }
      }

      tbody {
        td {
          padding: 1.6rem 0 1.6rem 1.6rem;
          border-bottom: solid .1rem var(--cp-color-app-border);
        }

        & > td:last-child {
          padding-right: 1.6rem;
        }

        > tr:last-child > td {
          border: none;
        }
      }
    }
    
    table.ie-table-selectable {
      tbody tr {
        td {
          transition: background-color 0.1s;
          cursor: default;
        }
        &:not(.--selected):hover td {
          background-color: var(--cp-color-nav-active-border);
        }
        &.--selected {
          background-color: var(--cp-color-table-row-selected-bg);
        }
      }
    }
  }
`;

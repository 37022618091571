import React, { useState, useEffect } from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";

import { replaceDateWithLocale } from "./integrations.helper";
import { createAuthUrl } from "../integrations.resource";

export default function IntegrationSyncErrorModal({
  integrationInfo,
  checkIntegrationAuth,
  show,
  close,
}) {
  const [authUrlTrigger, setAuthUrlTrigger] = useState(false);

  useEffect(() => {
    if (!authUrlTrigger) return;

    const subscription = createAuthUrl(integrationInfo.type).subscribe(
      (authUrl) => {
        close();
        setAuthUrlTrigger(false);
        const oauthWindow = window.open(
          authUrl,
          `${integrationInfo?.type?.toUpperCase()} Login`,
          "width=800,height=800",
        );

        // when the window closes check the current connection status again
        const checkOauthWindow = () => {
          if (oauthWindow.closed) {
            checkIntegrationAuth(integrationInfo.type);
          } else {
            setTimeout(checkOauthWindow, 2000);
          }
        };
        // don't check if the window is closed the first time for 10 seconds
        setTimeout(checkOauthWindow, 5000);
      },
      (err) => {
        setAuthUrlTrigger(false);
        handleError(err);
      },
    );

    return () => subscription.unsubscribe();
  }, [authUrlTrigger, checkIntegrationAuth, close, integrationInfo.type]);

  return (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title={integrationInfo?.disconnect_error?.title} />
      <CpModal.Body>
        {integrationInfo.disconnect_error.date
          ? replaceDateWithLocale(integrationInfo.disconnect_error)
          : integrationInfo?.disconnect_error?.message}
      </CpModal.Body>
      {integrationInfo?.disconnect_error?.connection_title && (
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={() => {
              if (integrationInfo?.disconnect_error?.contact_support !== true) {
                setAuthUrlTrigger(true);
              }
            }}
            className="cp-mr-8"
            id={
              integrationInfo?.disconnect_error?.contact_support === true
                ? "canopy-intercom-launcher"
                : ""
            }
          >
            {integrationInfo?.disconnect_error?.connection_title}
          </CpButton>
          <CpButton btnType="flat" onClick={close}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      )}
    </CpModal>
  );
}

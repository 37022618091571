import React, { useState } from "react";
import { CpModal, CpButton, CpCheckbox } from "canopy-styleguide!sofe";

export default function PauseSyncModal({
  show,
  close,
  updatePaymentsSync,
}: {
  show: boolean;
  close: () => void;
  updatePaymentsSync: ({
    enabled,
    hidePausePaymentsModal,
  }: {
    enabled: boolean;
    hidePausePaymentsModal: boolean;
  }) => void;
}) {
  const [hidePausePaymentsModal, setHidePausePaymentsModal] = useState(false);

  return (
    <CpModal show={show} onClose={close}>
      <CpModal.Header title="Pause Payment Sync" />
      <CpModal.Body>
        <div>
          Any payments or credits created during the pause will not sync to QBO.
        </div>
        <CpCheckbox
          onChange={setHidePausePaymentsModal}
          checked={hidePausePaymentsModal}
          className="cp-pt-16 cp-pb-8"
        >
          Do not show this again
        </CpCheckbox>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            updatePaymentsSync({ enabled: false, hidePausePaymentsModal });
            close();
          }}
          className="cp-mr-8"
        >
          Pause
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

import React from "react";
import Disposable from "react-disposable-decorator";
import { Scoped, k } from "kremling";
import {
  CpButton,
  CpOverlay,
  CpLoader,
  CpEmptyState,
} from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { warningToast, successToast } from "toast-service!sofe";
import { isEmpty } from "lodash";
import { onPusher } from "fetcher!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";

import {
  getSyncPreview,
  cancelSync,
  prepareServiceItemsSync,
  startServiceItemsSync,
  getIntegrationStatus,
  getLateFeeData,
  updateLateFeeData,
} from "../../integrations.resource";
import NewServiceItemSection from "./sections/service-items-new-section.component";
import ServiceItemMatchesSection from "./sections/service-items-matches-section.component";
import ServiceItemUnmatchedSection from "./sections/service-items-unmatched-section.component";
import PreviewNav from "./preview-nav.component";
import {
  findSectionsWithData,
  getInstructionPageObjects,
  getActiveSection,
  sections,
  getAllSectionsVisited,
  getLastSection,
} from "./sync-preview.helpers";
import Instructions from "./instructions.component";

@Disposable
@UserTenantProps({
  permissions: {
    hasLateFees: "settings_invoice_late_fees",
  },
})
export default class ServiceItemsPreview extends React.Component {
  state = {
    activeSection: sections.SERVICES_INSTRUCTIONS,
    lateFeeData: null,
    lateFeesSetupRequired: false,
    loading: false,
    syncing: false,
    itemsPreviewData: {
      unmatchedCanopy: [],
      matches: [],
      newServiceItems: [],
    },
    sectionsVisited: [],
    sectionsWithData: [],
  };

  componentDidMount() {
    if (!this.props.hasInvoicePermissions) {
      this.setState({
        activeSection: sections.INVOICE_PERMISSION_INSTRUCTIONS,
      });
    }

    if (
      featureEnabled("toggle_gs_late_fees_qbo") &&
      this.props.permissions?.hasLateFees
    ) {
      this.getLateFeeData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      featureEnabled("toggle_gs_late_fees_qbo") &&
      !prevProps.permissions?.hasLateFees &&
      this.props.permissions?.hasLateFees
    ) {
      this.getLateFeeData();
    }
  }

  getLateFeeData = () => {
    this.props.cancelWhenUnmounted(
      getLateFeeData().subscribe((lateFeeData) => {
        this.setState({
          lateFeeData,
          lateFeesSetupRequired:
            lateFeeData?.active && !lateFeeData?.third_party_account_id,
        });
      }, handleError),
    );
  };

  updateLateFeeInfo = () => {
    this.setState({ loading: true, syncing: true });
    this.props.cancelWhenUnmounted(
      updateLateFeeData(this.state.lateFeeData).subscribe(
        () => {
          this.setState({
            activeSection: sections.SERVICES_CONFIRMATION,
            loading: false,
            syncing: false,
          });
        },
        (err) => {
          this.setState({ loading: false, syncing: false });
          handleError(err);
        },
      ),
    );
  };

  getSyncPreviewData = (syncId) => {
    this.props.cancelWhenUnmounted(
      getSyncPreview(this.props.qboIntegrationInfo.id, syncId).subscribe(
        (previewData) => {
          if (previewData.status === "ERROR_PREPARING") {
            warningToast({
              message: "An issue occurred while preparing QBO data.",
              actionText: "Try again",
              actionCallback: () =>
                this.props.setShowSyncPreview({
                  showSyncPreview: true,
                }),
            });
            return this.props.setShowSyncPreview({
              showSyncPreview: false,
            });
          } else if (previewData.status === "PREPARING") {
            // if the data isn't ready yet then start the tickle
            return this.itemsPreviewTickle(syncId);
          }
          const itemsPreviewData = {
            unmatchedCanopy:
              (!isEmpty(previewData?.unmatched_canopy) &&
                previewData.unmatched_canopy) ||
              [],
            matches:
              (!isEmpty(previewData?.to_match_details) &&
                previewData.to_match_details) ||
              [],
            newServiceItems:
              (!isEmpty(previewData?.to_create_in_canopy) &&
                previewData.to_create_in_canopy) ||
              [],
          };

          const { activeSection, sectionsVisited, sectionsWithData } =
            findSectionsWithData(itemsPreviewData);

          this.setState({
            itemsPreviewData,
            loading: false,
            activeSection,
            sectionsVisited,
            sectionsWithData,
          });
        },
        handleError,
      ),
    );
  };

  prepareItemsSync = () => {
    this.props.cancelWhenUnmounted(
      prepareServiceItemsSync(
        this.props.qboIntegrationInfo.id,
        this.props.qboIntegrationInfo.type,
        this.props.clientSyncId,
      ).subscribe((data) => {
        this.getSyncPreviewData(data.id);
        this.props.setServiceItemId(data.id);
      }, handleError),
    );
  };

  // listening while service items sync data is being prepared
  itemsPreviewTickle = (syncId) => {
    // if the tickle hasn't returned after 15 seconds then try to get the preview data again
    const timeout = setTimeout(() => {
      if (itemsPreviewTickle) {
        itemsPreviewTickle.unsubscribe();
      }
      this.getSyncPreviewData(syncId);
    }, 15000);

    const itemsPreviewTickle = onPusher("items-preview").subscribe(
      (data) => {
        if (data?.id) {
          clearTimeout(timeout);
          this.getSyncPreviewData(data.id);
          this.props.setServiceItemId(data.id);
        }
      },
      (err) => {
        clearTimeout(timeout);
        handleError(err);
      },
    );

    this.props.cancelWhenUnmounted(itemsPreviewTickle);
  };

  startItemsSync = () => {
    this.setState({ loading: true, syncing: true });

    this.props.cancelWhenUnmounted(
      startServiceItemsSync(
        this.props.qboIntegrationInfo.id,
        this.props.qboIntegrationInfo.type,
        this.props.serviceItemId,
      ).subscribe(() => {
        this.getServiceItemsSyncStatus();
      }, handleError),
    );
  };

  getServiceItemsSyncStatus = () => {
    this.props.cancelWhenUnmounted(
      getIntegrationStatus(
        this.props.qboIntegrationInfo.id,
        this.props.qboIntegrationInfo.type,
        this.props.serviceItemId,
      ).subscribe((data) => {
        if (data.status === "IN_PROGRESS") {
          return this.itemsSyncedTickle();
        } else if (data.status === "SUCCESS") {
          successToast("QuickBooks Online service items successfully synced!");
        } else if (data.status === "PARTIAL_SUCCESS") {
          warningToast({
            message:
              "An issue occurred while syncing and some service items may be affected.",
            actionText: " See integration report",
            actionCallback: () =>
              this.props.setShowSyncPreview({
                showSyncPreview: true,
                syncPreviewReadOnly: true,
              }),
          });
        } else if (data.status === "FATAL_ERROR_SYNCING") {
          warningToast({
            message: "An issue occurred while syncing.",
            actionText: "Try again",
            actionCallback: () =>
              this.props.setShowSyncPreview({
                showSyncPreview: true,
              }),
          });

          return this.props.setShowSyncPreview({
            showSyncPreview: false,
          });
        }

        this.props.setServiceItemId();

        let activeSection = sections.SERVICES_CONFIRMATION;

        if (this.state.lateFeesSetupRequired) {
          activeSection = sections.LATE_FEES_SETUP;
        }

        this.setState({
          loading: false,
          syncing: false,
          sectionsVisited: [],
          sectionsWithData: [],
          activeSection,
        });
      }, handleError),
    );
  };

  // listening to when item sync is done
  itemsSyncedTickle = () => {
    // if the tickle hasn't returned after 15 seconds then try to get sync status again
    const timeout = setTimeout(() => {
      if (serviceItemsSyncTickle) {
        serviceItemsSyncTickle.unsubscribe();
      }
      this.getServiceItemsSyncStatus();
    }, 15000);

    const serviceItemsSyncTickle = onPusher("items-synced").subscribe(
      () => {
        clearTimeout(timeout);
        this.getServiceItemsSyncStatus();
      },
      (err) => {
        clearTimeout(timeout);
        handleError(err);
      },
    );
  };

  setActiveSection = (activeSection) => {
    const visitedPreviously = this.state.sectionsVisited.find(
      (section) => section === activeSection,
    );
    if (visitedPreviously) {
      this.setState({ activeSection });
    } else {
      this.setState((prevState) => {
        return {
          activeSection,
          sectionsVisited: [...prevState.sectionsVisited, activeSection],
        };
      });
    }
  };

  nextSection = (sectionsWithData, currentSection) => {
    const currentIndex = sectionsWithData.findIndex(
      (section) => section === currentSection,
    );
    this.setActiveSection(sectionsWithData[currentIndex + 1]);
  };

  cancelSyncPreview = () => {
    const { activeSection } = this.state;

    const shouldCancelSync =
      activeSection === sections.UNMATCHED_CANOPY ||
      activeSection === sections.MATCHES ||
      activeSection === sections.NEW_SERVICE_ITEMS;

    if (shouldCancelSync) {
      this.setState({ loading: true });

      this.props.cancelWhenUnmounted(
        cancelSync(
          this.props.qboIntegrationInfo.id,
          this.props.serviceItemId,
        ).subscribe(
          () => {
            this.props.setShowSyncPreview({
              showSyncPreview: false,
            });
          },
          () => {
            this.props.setShowSyncPreview({
              showSyncPreview: false,
            });
          },
        ),
      );
    } else {
      this.props.setShowSyncPreview({
        showSyncPreview: false,
      });
    }
  };

  renderInstructionsComponent = () => {
    const {
      permissionsInstructionsObject,
      servicesInstructionsObject,
      servicesConfirmationObject,
      lateFeesObject,
    } = getInstructionPageObjects(this.state.activeSection);

    const {
      showServiceItemsInstructions,
      showServicesConfirmation,
      showLateFeesSetup,
    } = getActiveSection(this.state.activeSection);

    let instructions = {};
    if (showServiceItemsInstructions) {
      instructions = servicesInstructionsObject;
    } else if (showServicesConfirmation) {
      instructions = servicesConfirmationObject;
      if (this.props.hasPayments) {
        instructions.instructions = [
          ...instructions.instructions,
          "Next step is helping you sync payments with QuickBooks Online.",
        ];
      }
    } else if (showLateFeesSetup) {
      instructions = {
        ...lateFeesObject,
        lateFeeData: this.state.lateFeeData,
        updateLateFeeData: (lateFeeData) => this.setState({ lateFeeData }),
      };
    } else {
      instructions = permissionsInstructionsObject;
    }

    return (
      <Instructions
        {...instructions}
        integration={this.props.qboIntegrationInfo}
      />
    );
  };

  getNavButtonText = (
    showInstructions,
    allSectionsVisited,
    lastSection,
    activeSection,
    showServicesConfirmation,
    showLateFeesSetup,
  ) => {
    if (showInstructions && !showServicesConfirmation && !showLateFeesSetup) {
      return "Start";
    } else if (allSectionsVisited || lastSection === activeSection) {
      return "Sync service items";
    } else if (
      this.props.hasPayments &&
      (showServicesConfirmation || showLateFeesSetup)
    ) {
      return "Continue";
    } else if (showServicesConfirmation || showLateFeesSetup) {
      return "Finish";
    } else {
      return "Next";
    }
  };

  getNavBtnClickFunction = (
    showInstructions,
    allSectionsVisited,
    showServicesConfirmation,
    showLateFeesSetup,
  ) => {
    const { sectionsWithData, activeSection } = this.state;
    const { setShowSyncPreview, setShowPaymentsPreview, hasPayments } =
      this.props;

    if (showInstructions && !showServicesConfirmation && !showLateFeesSetup) {
      this.setState({ loading: true, activeSection: "" });

      return this.prepareItemsSync();
    } else if (allSectionsVisited) {
      this.startItemsSync();
    } else if (showLateFeesSetup) {
      this.updateLateFeeInfo();
    } else if (hasPayments && showServicesConfirmation) {
      setShowPaymentsPreview();
    } else if (showServicesConfirmation) {
      setShowSyncPreview({
        showSyncPreview: false,
      });
    } else {
      return this.nextSection(sectionsWithData, activeSection);
    }
  };

  render() {
    const {
      activeSection,
      loading,
      syncing,
      itemsPreviewData,
      sectionsWithData,
      sectionsVisited,
      lateFeeData,
    } = this.state;

    const {
      showServiceItemsInstructions,
      showServicesConfirmation,
      unmatchedCanopyInView,
      matchesInView,
      newServiceItemsInView,
      showPermissionInstructions,
      showLateFeesSetup,
    } = getActiveSection(activeSection);
    const showInstructions =
      showServiceItemsInstructions ||
      showPermissionInstructions ||
      showLateFeesSetup ||
      showServicesConfirmation;
    const allSectionsVisited = getAllSectionsVisited(
      sectionsWithData,
      sectionsVisited,
    );
    const lastSection = getLastSection(itemsPreviewData);
    const showEmptyState = !showInstructions && isEmpty(sectionsWithData);

    return (
      <>
        <CpOverlay.Header title="Sync Service Items">
          <Scoped css={css}>
            {!showEmptyState && !showPermissionInstructions && (
              <>
                {showServiceItemsInstructions &&
                  !showPermissionInstructions &&
                  this.props.syncStartSection !== "items" &&
                  this.props.syncStartSection !== "invoices" && (
                    <CpButton
                      className="cp-mr-8"
                      btnType="secondary"
                      onClick={this.cancelSyncPreview}
                    >
                      Not now
                    </CpButton>
                  )}
                <CpButton
                  btnType="primary"
                  onClick={() =>
                    this.getNavBtnClickFunction(
                      showInstructions,
                      allSectionsVisited,
                      showServicesConfirmation,
                      showLateFeesSetup,
                    )
                  }
                  disabled={
                    (!showInstructions &&
                      !allSectionsVisited &&
                      lastSection === activeSection) ||
                    loading ||
                    (showLateFeesSetup && !lateFeeData.third_party_account_id)
                  }
                  showLoader={syncing}
                >
                  {this.getNavButtonText(
                    showInstructions,
                    allSectionsVisited,
                    lastSection,
                    activeSection,
                    showServicesConfirmation,
                    showLateFeesSetup,
                  )}
                </CpButton>
              </>
            )}
          </Scoped>
        </CpOverlay.Header>
        <CpOverlay.Body>
          <Scoped css={css}>
            {showInstructions ? (
              this.renderInstructionsComponent()
            ) : !loading && !showEmptyState ? (
              <div className="sync-preview">
                <div className="left-nav-container">
                  <PreviewNav
                    syncPreviewData={itemsPreviewData}
                    unmatchedCanopyInView={unmatchedCanopyInView}
                    matchesInView={matchesInView}
                    newServiceItemsInView={newServiceItemsInView}
                    setActiveSection={this.setActiveSection}
                    sectionsVisited={sectionsVisited}
                    isActiveBox
                  />
                </div>
                <div className="right-side-container">
                  {unmatchedCanopyInView && (
                    <ServiceItemUnmatchedSection
                      unmatchedCanopy={itemsPreviewData.unmatchedCanopy}
                    />
                  )}
                  {matchesInView && (
                    <ServiceItemMatchesSection
                      matches={itemsPreviewData.matches}
                    />
                  )}
                  {newServiceItemsInView && (
                    <NewServiceItemSection
                      newServiceItems={itemsPreviewData.newServiceItems}
                    />
                  )}
                </div>
              </div>
            ) : showEmptyState && !loading ? (
              <div className="sync-preview">
                <CpEmptyState
                  img="es_tasks_list"
                  text="No service items data is available for syncing"
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                {syncing
                  ? "Syncing service items"
                  : "Getting service items from QBO"}
                <CpLoader />
              </div>
            )}
          </Scoped>
        </CpOverlay.Body>
      </>
    );
  }
}

const css = k`
  .sync-preview {
    display: flex;
  }

  .right-side-container {
    margin-left: 28.8rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .left-nav-container {
    margin-left: 1.6rem;
    position: fixed;
  }
`;

import React, { useState, useEffect } from "react";
import {
  CpButton,
  CpInput,
  CpLoader,
  CpModal,
  CpRadio,
} from "canopy-styleguide!sofe";
import { useDebounceValue } from "../../use-debounce-value";
import {
  patchClient,
  suggestDisplayName,
  validateDisplayName,
} from "../../../integrations.resource";
import { handleError } from "src/handle-error";
import { k, useCss } from "kremling";

type Props = {
  displayName: string;
  onSubmit: (submitted: boolean) => void;
  integrationId: number;
  clientId: number;
  refetchClient: () => void;
};

export function DisplayNameConflict(props: Props) {
  const scope = useCss(css);
  const { displayName, onSubmit, integrationId, clientId, refetchClient } =
    props;
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [choice, setChoice] = useState<string>();
  const [custom, setCustom] = useState("");
  const [validCustom, setValidCustom] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const debouncedCustom = useDebounceValue(custom.trim(), 700);

  useEffect(() => {
    setLoadingSuggestions(true);
    const sub = suggestDisplayName(
      integrationId,
      clientId,
      displayName,
    ).subscribe(
      (res: Record<number, string>) => {
        setSuggestions(Object.values(res));
        setLoadingSuggestions(false);
      },
      (e: Error) => {
        setLoadingSuggestions(false);
        handleError(e);
      },
    );
    return () => {
      setLoadingSuggestions(false);
      sub?.unsubscribe();
    };
  }, [integrationId, clientId, displayName]);

  useEffect(() => {
    if (debouncedCustom?.length) {
      setLoading(true);
      const sub = validateDisplayName(integrationId, debouncedCustom).subscribe(
        (nextIsValid: boolean) => {
          setValidCustom(nextIsValid);
          setLoading(false);
        },
        (e: Error) => {
          setLoading(false);
          handleError(e);
        },
      );
      return () => {
        setLoading(false);
        sub?.unsubscribe();
      };
    }
  }, [debouncedCustom]); // eslint-disable-line react-hooks/exhaustive-deps

  const onContinue = async () => {
    setSaving(true);
    try {
      await new Promise((resolve, reject) => {
        patchClient(clientId, {
          display_name: choice !== "custom" ? choice : custom.trim(),
        }).subscribe(resolve, (e: Error) => {
          handleError(e);
          reject(e);
        });
      });
      refetchClient();
      onSubmit(true);
    } catch (e) {
      onSubmit(false);
    }
  };

  const showErrorMessage =
    !loading && !validCustom && !!debouncedCustom.trim().length;

  return (
    <>
      <CpModal.Header title="Unique Display Name Required" />
      <CpModal.Body>
        <div className="cp-mb-24">
          The display name, <em>{displayName}</em>, already exists in QBO.
          Select from the options below to replace the display name with a
          unique version.
        </div>
        <div {...scope}>
          <div className="cp-mb-24">
            {loadingSuggestions ? (
              <div>
                <CpLoader />
              </div>
            ) : (
              <CpRadio
                name="displayName"
                value={choice}
                onChange={(val) => setChoice(val as string)}
              >
                {suggestions.map((suggestion) => (
                  <CpRadio.Item
                    key={suggestion}
                    id={suggestion}
                    style={{ paddingBottom: ".8rem" }}
                  >
                    {suggestion}
                  </CpRadio.Item>
                ))}
                <CpRadio.Item id="custom">
                  <div style={{ position: "relative" }}>
                    Create your own
                    <div className="create-your-own">
                      <CpInput
                        placeholder="Enter display name"
                        value={custom}
                        error={
                          showErrorMessage &&
                          "This display name is already in use"
                        }
                        onChange={(value) => setCustom(value.trimStart())}
                        style={{
                          position: "relative",
                          top: "auto",
                          width: "24rem",
                        }}
                        onFocus={() => {
                          if (choice !== "custom") {
                            setChoice("custom");
                          }
                        }}
                      />
                    </div>
                  </div>
                </CpRadio.Item>
              </CpRadio>
            )}
          </div>
          <div className="cp-pl-24"></div>
        </div>
      </CpModal.Body>
      <CpModal.Footer className="flex cp-gap-8">
        <CpButton
          onClick={onContinue}
          disabled={
            choice === "custom" ? !validCustom || !displayName.length : !choice
          }
          showLoader={loading || saving}
        >
          Continue
        </CpButton>
        <CpButton
          btnType="flat"
          onClick={() => onSubmit(false)}
          disabled={saving}
        >
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}

const css = k`
  .create-your-own {
    position: absolute;
    top: -.5rem;
    left: 12rem;
  }
`;

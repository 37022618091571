import React from "react";
import { useCss, k, always } from "kremling";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import { sections } from "./sync-preview.helpers";

export default function PreviewNav({
  syncPreviewData = [],
  duplicatesInView,
  unmatchedCanopyInView,
  unmatchedThirdPartyInView,
  matchesInView,
  newClientsInView,
  setActiveSection,
  sectionsVisited = [],
  readOnly = false,
  newServiceItemsInView,
  headerText,
  setActiveBox,
  isActiveBox,
  isClients = false,
}) {
  const scope = useCss(css);
  const duplicatesVisited = sectionsVisited.includes(sections.DUPLICATES);
  const unmatchedCanopyVisited = sectionsVisited.includes(
    sections.UNMATCHED_CANOPY,
  );
  const matchesVisited = sectionsVisited.includes(sections.MATCHES);
  const newClientsVisited = sectionsVisited.includes(sections.NEW_CLIENTS);
  const newServiceItemsVisited = sectionsVisited.includes(
    sections.NEW_SERVICE_ITEMS,
  );

  const setActiveBoxAndSection = (section) => {
    if (readOnly && !isActiveBox) {
      setActiveBox(section);
    } else {
      setActiveSection(section);
    }
  };

  return (
    <div className="left-nav-box" {...scope}>
      {readOnly && <div className="box-header">{headerText}</div>}

      {!isEmpty(syncPreviewData?.duplicates) && (
        <div
          className={always("nav-item")
            .m("active-nav-item", isActiveBox && duplicatesInView)
            .m("visited", readOnly || duplicatesVisited)}
          onClick={() => setActiveBoxAndSection(sections.DUPLICATES)}
        >
          <div>
            <CpIcon name="crud-duplicate" /> Duplicates (
            {syncPreviewData.duplicates.length})
            {!readOnly && (
              <CpTooltip
                text="Duplicates will NOT be imported with your sync."
                position="bottom-start"
              >
                <CpIcon
                  name="alert-triangle-open-large"
                  fill="var(--cp-color-app-warning-text)"
                  className="cp-ml-4 cp-mb-4"
                />
              </CpTooltip>
            )}
          </div>
          {!readOnly &&
            (duplicatesVisited ? (
              <CpIcon
                className="visited-icon"
                name="checkmark-large"
                fill="var(--cp-color-checkbox-selected-bg)"
              />
            ) : (
              <CpIcon
                className="circle-size"
                name="shape-circle-open"
                fill="var(--cp-color-app-secondary-text)"
              />
            ))}
        </div>
      )}
      {!isEmpty(syncPreviewData?.unmatchedCanopy) && (
        <div
          className={always("nav-item")
            .m("active-nav-item", isActiveBox && unmatchedCanopyInView)
            .m("visited", readOnly || unmatchedCanopyVisited)}
          onClick={() => setActiveBoxAndSection(sections.UNMATCHED_CANOPY)}
        >
          <div>
            <CpIcon name="broken-link" />
            {isClients && readOnly && "Canopy "}Unmatched (
            {syncPreviewData.unmatchedCanopy.length})
            {!readOnly && (
              <CpTooltip
                text={`Unmatched ${
                  isClients ? "Canopy clients" : "service items"
                } will NOT be synced with your QBO ${
                  isClients ? "clients" : "service items"
                }.`}
                position="bottom-start"
              >
                <CpIcon
                  name="alert-triangle-open-large"
                  fill="var(--cp-color-app-warning-text)"
                  className="cp-ml-4 cp-mb-4"
                />
              </CpTooltip>
            )}
          </div>
          {!readOnly &&
            (unmatchedCanopyVisited ? (
              <CpIcon
                className="visited-icon"
                name="checkmark-large"
                fill="var(--cp-color-checkbox-selected-bg)"
              />
            ) : (
              <CpIcon
                className="circle-size"
                name="shape-circle-open"
                fill="var(--cp-color-app-secondary-text)"
              />
            ))}
        </div>
      )}
      {!isEmpty(syncPreviewData?.unmatchedThirdParty) && (
        <div
          className={always("nav-item")
            .m("active-nav-item", isActiveBox && unmatchedThirdPartyInView)
            .m("visited", readOnly)}
          onClick={() => setActiveBoxAndSection(sections.UNMATCHED_THIRD_PARTY)}
        >
          <div>
            <CpIcon name="broken-link" />
            QBO Unmatched ({syncPreviewData.unmatchedThirdParty.length})
          </div>
        </div>
      )}
      {!isEmpty(syncPreviewData?.matches) && (
        <div
          className={always("nav-item")
            .m("active-nav-item", isActiveBox && matchesInView)
            .m("visited", readOnly || matchesVisited)}
          onClick={() => setActiveBoxAndSection(sections.MATCHES)}
        >
          <div>
            <CpIcon name="misc-link" /> Matches (
            {syncPreviewData.matches.length})
          </div>
          {!readOnly &&
            (matchesVisited ? (
              <CpIcon
                className="visited-icon"
                name="checkmark-large"
                fill="var(--cp-color-checkbox-selected-bg)"
              />
            ) : (
              <CpIcon
                className="circle-size"
                name="shape-circle-open"
                fill="var(--cp-color-app-secondary-text)"
              />
            ))}
        </div>
      )}
      {!isEmpty(syncPreviewData?.newClients) && (
        <div
          className={always("nav-item")
            .m("active-nav-item", isActiveBox && newClientsInView)
            .m("visited", readOnly || newClientsVisited)}
          onClick={() => setActiveBoxAndSection(sections.NEW_CLIENTS)}
          disabled
        >
          <div>
            <CpIcon name="person" /> New clients (
            {syncPreviewData.newClients.length})
          </div>
          {!readOnly &&
            (newClientsVisited ? (
              <CpIcon
                className="visited-icon"
                name="checkmark-large"
                fill="var(--cp-color-checkbox-selected-bg)"
              />
            ) : (
              <CpIcon
                className="circle-size"
                name="shape-circle-open"
                fill="var(--cp-color-app-secondary-text)"
              />
            ))}
        </div>
      )}
      {!isEmpty(syncPreviewData?.newServiceItems) && (
        <div
          className={always("nav-item")
            .m("active-nav-item", isActiveBox && newServiceItemsInView)
            .m("visited", readOnly || newServiceItemsVisited)}
          onClick={() => setActiveBoxAndSection(sections.NEW_SERVICE_ITEMS)}
          disabled
        >
          <div>
            <CpIcon name="billing-square-open" /> New service items (
            {syncPreviewData.newServiceItems.length})
          </div>
          {!readOnly &&
            (newServiceItemsVisited ? (
              <CpIcon
                className="visited-icon"
                name="checkmark-large"
                fill="var(--cp-color-checkbox-selected-bg)"
              />
            ) : (
              <CpIcon
                className="circle-size"
                name="shape-circle-open"
                fill="var(--cp-color-app-secondary-text)"
              />
            ))}
        </div>
      )}
    </div>
  );
}

const css = k`
  .left-nav-box {
    border: 0.1rem solid var(--cp-color-card-border);
    border-radius: 0.1rem;
    width: 25.5rem;
    padding: 0.8rem 0;
    box-shadow: 0 1rem 3rem -2.4rem var(--cp-color-well-border);
    margin-bottom: 2.4rem;
  }

  .active-nav-item {
    color: var(--cp-color-button-flat-text) !important;
    border-left: 0.2rem solid var(--cp-color-button-flat-text);
    padding-left: 1.4rem !important;
  }

  .nav-item {
    color: var(--cp-color-app-inactive-text);
    padding: 0.8rem 1.6rem;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .nav-item:hover {
    color: var(--cp-color-button-flat-text);
    cursor: pointer;
    background-color: var(--cp-color-menu-hover-bg);
  }

  .visited {
    color: var(--cp-color-app-primary-text);
  }

  .visited-icon {
    margin-right: -0.6rem;
  }

  .circle-size {
    height: 1.4rem;
    width: 1.4rem;
    margin-top: 0.5rem;
  }

  .box-header {
    padding: 0.8rem 0 1.2rem 1.6rem;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--cp-color-well-border);
  }
`;

import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { getAccounts } from "./email-integration.resource";
import { handleError } from "src/handle-error";
import { mergeMap } from "rxjs/operators";
import { from } from "rxjs";
import Cancelable from "react-disposable-decorator";
import { UserTenantProps } from "cp-client-auth!sofe";

@UserTenantProps()
@Cancelable
export class EmailIntegrationConnect extends React.Component {
  launchConnectAccount = () => {
    this.props.cancelWhenUnmounted(
      getAccounts().subscribe((accounts) => {
        from(SystemJS.import("communications-ui!sofe"))
          .pipe(
            mergeMap((communicationsUI) => {
              return communicationsUI.connectAccountModal({
                callerContext: "prompt-for-new",
                accounts,
                routeUser: this.props.user,
              });
            }),
          )
          .subscribe(() => {}, handleError);
      }, handleError),
    );
  };

  render() {
    return (
      <CpButton
        block
        btnType="secondary"
        onClick={() => this.launchConnectAccount()}
      >
        Connect
      </CpButton>
    );
  }
}

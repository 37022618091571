import React from "react";
import { useCss, k } from "kremling";
import { CpButton, CpCard, CpHeader } from "canopy-styleguide!sofe";

type IntegrationType = {
  logo: string;
  logoHeight: string;
  logoAltText: string;
  name: string;
  description: string;
  hasPermission: {
    info: string;
    infoLink: string;
    action: string;
    buttonText: string;
  };
  noPermission: {
    info: string;
    action?: string;
    actionLink?: string;
    buttonText: string;
  };
};

export function IntegrationCard({
  type,
  hasPermission,
}: {
  type: IntegrationType;
  hasPermission: boolean;
}) {
  const scope = useCss(css);

  return (
    <div {...scope}>
      <CpCard className="integration-card-container">
        <div className="integration-card-logo">
          <img
            src={type.logo}
            height={type.logoHeight}
            style={{ height: type.logoHeight }}
            alt={type.logoAltText}
          />
        </div>
        <CpHeader level={1} className="integration-card-title">
          {type.name}
        </CpHeader>
        <div className="integration-card-description">
          <div className="info-description">{type.description}</div>
          <div className="info-permission">
            {hasPermission && (
              <a
                className="info-permission-color"
                href={type.hasPermission.infoLink}
                target="_blank"
                rel="noreferrer"
              >
                {type.hasPermission.info}
              </a>
            )}
          </div>
        </div>
        <div className="integration-card-button">
          {hasPermission && (
            <CpButton
              anchor
              block
              btnType="secondary"
              href={`${type.hasPermission.action}`}
            >
              {type.hasPermission.buttonText}
            </CpButton>
          )}
          {!hasPermission && (
            <>
              {type.noPermission.action && (
                <CpButton
                  btnType="secondary"
                  block
                  id={type.noPermission.action}
                >
                  {type.noPermission.buttonText}
                </CpButton>
              )}
              {type.noPermission.actionLink && (
                <CpButton
                  btnType="secondary"
                  anchor
                  block
                  href={type.noPermission.actionLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {type.noPermission.buttonText}
                </CpButton>
              )}
              <div className="upgrade-needed">{type.noPermission.info}</div>
            </>
          )}
        </div>
      </CpCard>
    </div>
  );
}

const css = k`
  .integration-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 31.4rem;
    width: 26.8rem;
    padding: 2.8rem 1.6rem 4.4rem;
    border-radius: 0.5rem;
    box-shadow: none !important;
  }

  .integration-card-logo {
    max-height: 6rem !important;
    max-width: 20rem !important;
    margin: auto;
  }

  .integration-card-title {
    justify-content: center;
    padding: 0;
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    font-weight: 600;
    border-bottom: none !important;
  }

  .integration-card-description {    
    width: 23.4rem;  
    height: 8rem;
    font-size: 1.4rem !important;
    line-height: 2.0rem !important;
    text-align: center;
    margin: auto;
  }

  .info-description {
    min-height: 4rem;
  }

  .info-permission {
    margin: 1.8rem auto 0;
    font-size: 1.2rem !important;
  }
  
  .info-no-permission-color {
    color: var(--cp-color-app-callout-text);
  }
  
  .info-permission-color {
    color: var(--cp-color-app-link-text);
    cursor: pointer;
  }

  .integration-card-button {
    position: relative;
    display: block;
    width: 23.4rem;  
  }

  .upgrade-needed {
    position: absolute;
    bottom: -2.4rem;
    left: 0;
    right: 0;
    font-size: 1.2rem !important;
    text-align: center;
  }
`;

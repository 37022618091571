import React from "react";
import { cdnImage, CpIcon } from "canopy-styleguide!sofe";
import {
  getClientName,
  getQboAddress,
  formatPhoneNumber,
} from "../sync-preview.helpers";
import { get } from "lodash";

export function CanopyClientRow({ canopyClient, newCrmEnabled }) {
  const canopyClientName = getClientName(canopyClient, newCrmEnabled, "canopy");
  const client = {
    id: canopyClient.id,
    name: canopyClientName,
    displayName: canopyClient?.display_name,
    phone: canopyClient?.phone,
    email: canopyClient?.email,
    address: canopyClient?.street_address,
    isBusiness: canopyClient?.is_business,
    locality: canopyClient?.locality,
    region: canopyClient?.region,
    postal_code: canopyClient?.postal_code,
    country: canopyClient?.country,
    image: {
      src: "black-delta-mark.svg",
      height: "16rem",
      alt: "Canopy Logo",
    },
    active: canopyClient.is_active,
  };

  return <ClientRow client={client} newCrmEnabled={newCrmEnabled} />;
}

export function QboClientRow({ qboClient, newCrmEnabled }) {
  if (!qboClient) return {};

  let client = {
    id: qboClient.third_party_client_id,
    displayName: qboClient.display_name,
    isBusiness: qboClient.is_business,
    active: qboClient.is_active,
    image: {
      src: "qbo_logo_small_circle.svg",
      height: "20rem",
      alt: "QuickBooks Online Logo",
    },
  };

  if (newCrmEnabled) {
    client = {
      ...client,
      name: qboClient.name,
      phone: qboClient.phone,
      email: qboClient.email,
      address: qboClient.street_address,
      locality: qboClient.locality,
      region: qboClient.region,
      postal_code: qboClient.postal_code,
      country: qboClient.country,
    };
  } else {
    const qboClientAddress = getQboAddress(qboClient?.addresses);
    const qboClientName = getClientName(qboClient, newCrmEnabled, "qbo");
    client = {
      ...client,
      name: qboClientName,
      phone: get(qboClient, "phones[0].value"),
      email: get(qboClient, "emails[0].value"),
      address: qboClientAddress?.address_1,
      locality: qboClientAddress?.locality,
      region: qboClientAddress?.region,
      postal_code: qboClientAddress?.postal_code,
      country: qboClientAddress?.country,
    };
  }

  return <ClientRow client={client} newCrmEnabled={newCrmEnabled} />;
}

function ClientRow({ client, newCrmEnabled }) {
  const blankField = "——";
  return (
    <tr className="border-top">
      <td className="sticky-cell">
        <div className="client-name-cell">
          <div className="name-initials" style={{ marginLeft: "-8px" }}>
            <CpIcon
              name={client.isBusiness ? "misc-buildings" : "person"}
              fill="var(--cp-color-app-secondary-text)"
            />
            <div className="client-name cp-ml-8">
              {newCrmEnabled ? client.displayName : client.name}
            </div>
          </div>
          <div className="logo-right">
            <img
              src={cdnImage(client.image.src)}
              height={client.image.height}
              alt={client.image.alt}
            />
          </div>
        </div>
      </td>
      <td className="small-col">
        {(newCrmEnabled ? client.name : client.displayName) || blankField}
      </td>
      <td className="small-col">
        {formatPhoneNumber(client.phone) || blankField}
      </td>
      <td>{client.email || blankField}</td>
      <td>{client.address || blankField}</td>
      <td className="small-col">{client.locality || blankField}</td>
      <td className="small-col">{client.region || blankField}</td>
      <td className="small-col">{client.postal_code || blankField}</td>
      <td className="small-col">{client.country || blankField}</td>
      <td className="small-col">
        {client.active && <CpIcon name="checkmark-large" />}
      </td>
    </tr>
  );
}

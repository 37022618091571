import React, { useEffect, useState, useRef } from "react";
import {
  CpCard,
  CpButton,
  CpDropdown,
  CpIcon,
  CpLoader,
  CpInlineNotification,
  CpTooltip,
} from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { of, combineLatest } from "rxjs";
import { mergeMap, map } from "rxjs/operators";
import { successToast, warningToast } from "toast-service!sofe";
import { k, useCss, maybe } from "kremling";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";

import {
  createAuthUrl,
  disconnectAuthUrl,
  getIntegrations,
  getLastSyncInfo,
  updatePaymentsSyncStatus,
} from "../integrations.resource.js";
import SyncSettingsList from "./sync-settings-list.component";
import SyncPreviewOverlay from "../qbo-integration/sync-preview/sync-preview.component.js";
import PauseSyncModal from "./pause-sync-modal.component";
import DisconnectModal from "./disconnect-modal.component";
import IntegrationSettingsOverlay from "./integration-settings-overlay.component";
import {
  AdjustQboSettingOverlay,
  ResumePaymentSyncModal,
} from "../qbo-integration/sync-preview/adjust-qbo-setting.component";
import { useCrmHierarchy } from "src/common/use-crm-hierarchy.helper";

import type { IntegrationInfo } from "../types/integration.d.ts";

const defaultIntegrationsInfo: IntegrationInfo = {
  type: "qbo",
  clients: {
    sync_status: {
      first_synced_at: undefined,
      synced_at: undefined,
      count: 0,
    },
  },
  items: {
    sync_status: {
      first_synced_at: undefined,
      synced_at: undefined,
      count: 0,
    },
  },
  invoices: {
    sync_status: {
      first_synced_at: undefined,
      synced_at: undefined,
      count: 0,
    },
  },
  payments: {
    sync_status: {
      first_synced_at: undefined,
      synced_at: undefined,
      count: 0,
    },
  },
  connected: false,
  id: "",
};

export default function ManageSettings() {
  const newCrmEnabled = useCrmHierarchy();
  const [user, tenant] = useWithUserAndTenant();
  const scoped = useCss(css);
  const getIntegrationsSub = useRef<any>();
  const disconnectSub = useRef<any>();
  const connectSub = useRef<any>();
  const syncPaymentsSub = useRef<any>();
  const hasClientsPermissions = useHasAccess(
    ["clients_not_assigned", "clients_create_edit"],
    true,
  );
  const hasInvoicePermissions = useHasAccess(
    [
      "billing_invoices",
      "billing_invoices_create_edit",
      "company_settings_billing",
    ],
    true,
  );
  const hasPaymentsPermissions = useHasAccess(
    ["billing_payments", "billing_payments_create_edit"],
    true,
  );

  const [syncId, setSyncId] = useState("");
  const [integrationInfo, setIntegrationInfo] = useState(
    defaultIntegrationsInfo,
  );
  const [showSyncPreview, setShowSyncPreview] = useState(false);
  const [syncPreviewReadOnly, setSyncPreviewReadOnly] = useState(false);
  const [syncStartSection, setSyncStartSection] = useState("clients");
  const [loadingSection, setLoadingSection] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [showPausePaymentModal, setShowPausePaymentModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [showAdjustQboSettingsOverlay, setShowAdjustQboSettingsOverlay] =
    useState(false);
  const [showResumePaymentModal, setShowResumePaymentModal] = useState(false);

  useEffect(() => {
    if (tenant?.qbo_credentials_id) {
      getIntegrationsSync();
    } else {
      setIntegrationInfo(defaultIntegrationsInfo);
    }

    return () => {
      getIntegrationsSub.current?.unsubscribe();
      disconnectSub.current?.unsubscribe();
      connectSub.current?.unsubscribe();
      syncPaymentsSub.current?.unsubscribe();
    };
  }, [tenant?.qbo_credentials_id]);

  const getIntegrationsSync = (popupClosed?: boolean, fetchLastSync = true) => {
    setLoadingTable(true);
    getIntegrationsSub.current = getIntegrations()
      .pipe(
        mergeMap((integrations: Array<IntegrationInfo>) => {
          const qboConnection = integrations.find(
            (integration: IntegrationInfo) => integration.type === "qbo",
          );

          return combineLatest(
            of(qboConnection),
            qboConnection?.connected && fetchLastSync
              ? getLastSyncInfo(qboConnection?.id)
              : of([]),
          );
        }),
        map(
          ([qboConnection, syncData]: [
            IntegrationInfo | undefined,
            { id: string },
          ]) => [qboConnection, syncData],
        ),
      )
      .subscribe(
        ([qboConnection, syncData]: [
          IntegrationInfo | undefined,
          { id: string },
        ]) => {
          if (qboConnection) {
            setIntegrationInfo(qboConnection);
          } else {
            setIntegrationInfo(defaultIntegrationsInfo);
          }
          if (popupClosed && qboConnection?.connected) {
            setShowSyncPreview(true);
          }
          setSyncId(syncData?.id);
          setLoadingTable(false);
        },
        (err: Error) => {
          setLoadingTable(false);
          handleError(err);
        },
      );
  };

  const disconnectIntegration = () => {
    disconnectSub.current = disconnectAuthUrl(
      integrationInfo.id,
      integrationInfo.type,
    ).subscribe((result: { success: boolean }) => {
      if (result?.success) {
        successToast("QBO integration has been disconnected");
      } else {
        warningToast("QBO integration was not disconnected successfully");
      }
    }, handleError);
  };

  const connectIntegration = () => {
    setLoadingSection(true);
    connectSub.current = createAuthUrl(integrationInfo.type).subscribe(
      (authUrl: string) => {
        const oauthWindow = window.open(
          authUrl,
          "QuickBooks Online Login",
          "width=800,height=800",
        );

        // when the window closes check the current qbo connection status again
        const checkOauthWindow = () => {
          if (oauthWindow?.closed) {
            getIntegrationsSync(true);
            setLoadingSection(false);
          } else {
            setTimeout(checkOauthWindow, 2000);
          }
        };
        // don't check if the window is closed the first time for 10 seconds
        setTimeout(checkOauthWindow, 5000);
      },
      (err: Error) => {
        setLoadingSection(false);
        handleError(err);
      },
    );
  };

  const updatePaymentsSync = ({
    enabled,
    hidePausePaymentsModal = false,
  }: {
    enabled: boolean;
    hidePausePaymentsModal?: boolean;
  }) => {
    if (
      enabled &&
      integrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit
    ) {
      setShowResumePaymentModal(true);
      return;
    }

    if (hidePausePaymentsModal && !enabled) {
      localStorage.setItem(
        "hidePausePaymentsModal",
        hidePausePaymentsModal.toString(),
      );
    }

    syncPaymentsSub.current = updatePaymentsSyncStatus(
      integrationInfo.id,
      integrationInfo.type,
      enabled,
      user,
    ).subscribe(() => {
      if (enabled) {
        successToast("QBO payments are now syncing");
      } else {
        successToast("QBO payments sync has been paused");
      }
      getIntegrationsSync();
    }, handleError);
  };

  return (
    <CpCard style={{ borderTop: "none" }}>
      <CpCard.Header
        style={{ borderBottom: "1px solid var(--cp-color-card-border)" }}
      >
        <h2 className="cp-subheader-sm" style={{ fontWeight: 500 }}>
          Manage QBO Integration
        </h2>

        <div className="cp-flex-center">
          {integrationInfo?.disconnect_error?.integrations_page_error && (
            <div {...scoped} className="qbo-card-error">
              {integrationInfo.disconnect_error.integrations_page_error}
            </div>
          )}
          {!!integrationInfo?.clients.sync_status.synced_at && (
            <CpTooltip text="Advanced settings" position="top-start">
              <CpButton
                onClick={() => {
                  getIntegrationsSync(false, false);
                  setShowAdvancedSettings(true);
                }}
                icon="misc-gear"
                aria-label="open advanced settings"
                className="cp-mr-8"
              />
            </CpTooltip>
          )}
          <CpDropdown
            position="bottom-end"
            contentWidth={240}
            renderTrigger={({ toggle }) => (
              <CpButton
                icon="misc-kabob"
                aria-label="integration-menu"
                onClick={toggle}
              />
            )}
            renderContent={() => (
              <div className="cp-select-list" {...scoped}>
                <button
                  onClick={() => {
                    if (
                      newCrmEnabled &&
                      !!integrationInfo?.clients?.sync_status?.synced_at
                    ) {
                      setSyncStartSection("restart");
                    } else {
                      setSyncStartSection("clients");
                    }
                    if (!integrationInfo?.connected) {
                      connectIntegration();
                    } else {
                      setShowSyncPreview(true);
                    }
                  }}
                >
                  <CpIcon
                    className="cp-select-list__icon-left"
                    name="af-line-clockwise"
                    fill="var(--cp-color-app-secondary-text)"
                  />
                  Restart sync
                </button>
                <button
                  onClick={() => {
                    setShowSyncPreview(true);
                    setSyncPreviewReadOnly(true);
                  }}
                  disabled={!syncId}
                  className={maybe("disabled-dropdown-item", !syncId)}
                >
                  <CpIcon
                    className="cp-select-list__icon-left"
                    name="misc-bar-graph-boxed"
                    fill={`var(--cp-color-app-${
                      syncId ? "secondary" : "inactive"
                    }-text`}
                  />
                  View integration report
                </button>
                <button
                  onClick={() => setShowDisconnectModal(true)}
                  disabled={!integrationInfo?.connected}
                  className={maybe(
                    "disabled-dropdown-item",
                    !integrationInfo?.connected,
                  )}
                >
                  <CpIcon
                    className="cp-select-list__icon-left"
                    name="broken-link"
                    fill={`var(--cp-color-app-${
                      integrationInfo?.connected ? "secondary" : "inactive"
                    }-text`}
                  />
                  Disconnect QBO Integration
                </button>
                {integrationInfo?.disconnect_error?.connection_title && (
                  <button onClick={connectIntegration}>
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name="person-key"
                      fill="var(--cp-color-app-secondary-text)"
                    />
                    Reauthorize connection
                  </button>
                )}
              </div>
            )}
          />
        </div>
      </CpCard.Header>
      {!!integrationInfo?.payments?.sync_status?.first_synced_at &&
        integrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit && (
          <div
            className="cp-p-16"
            style={{ borderBottom: "1px solid var(--cp-color-table-border)" }}
          >
            <CpInlineNotification
              message={
                <div>
                  <strong>Payment sync error:</strong> To resume the payment
                  sync and avoid balance mismatches, log into QBO and navigate
                  to Settings {">"} Advanced. Turn OFF the automation setting
                  titled “Automatically apply credits.”
                </div>
              }
              cta={
                <CpButton
                  btnType="secondary"
                  anchor
                  href="https://app.qbo.intuit.com/app/settings?p=advanced"
                  target="_blank"
                >
                  Adjust setting
                </CpButton>
              }
              type="error"
            />
          </div>
        )}
      {loadingTable ? (
        <CpLoader size="lg" />
      ) : (
        <SyncSettingsList
          integrationInfo={integrationInfo}
          syncId={syncId}
          startSync={(section) => {
            setSyncStartSection(section);
            if (!integrationInfo?.connected) {
              connectIntegration();
            } else {
              setShowSyncPreview(true);
            }
          }}
          updatePaymentsSync={({ enabled }) => {
            if (
              localStorage.getItem("hidePausePaymentsModal") != "true" &&
              !enabled
            ) {
              setShowPausePaymentModal(true);
            } else {
              updatePaymentsSync({ enabled });
            }
          }}
          loading={loadingSection}
          user={user}
        />
      )}
      {showSyncPreview && (
        <SyncPreviewOverlay
          setShowSyncPreview={({
            showSyncPreview,
            syncPreviewReadOnly = false,
          }: {
            showSyncPreview: boolean;
            syncPreviewReadOnly: boolean;
          }) => {
            if (showSyncPreview === false) {
              getIntegrationsSync();
            }
            setShowSyncPreview(showSyncPreview);
            setSyncPreviewReadOnly(syncPreviewReadOnly);
          }}
          showSyncPreview={showSyncPreview}
          qboIntegrationInfo={integrationInfo}
          clientSyncId={syncId}
          setClientSyncId={setSyncId}
          showReport={syncPreviewReadOnly}
          hasClientsPermissions={hasClientsPermissions}
          hasInvoicePermissions={hasInvoicePermissions}
          hasPaymentsPermissions={hasPaymentsPermissions}
          syncStartSection={syncPreviewReadOnly ? "clients" : syncStartSection}
        />
      )}
      <IntegrationSettingsOverlay
        show={showAdvancedSettings}
        close={() => setShowAdvancedSettings(false)}
        integrationInfo={integrationInfo}
        setIntegrationInfo={(integrationInfo: IntegrationInfo) =>
          setIntegrationInfo(integrationInfo)
        }
      />
      <PauseSyncModal
        show={showPausePaymentModal}
        close={() => setShowPausePaymentModal(false)}
        updatePaymentsSync={updatePaymentsSync}
      />
      <DisconnectModal
        show={showDisconnectModal}
        close={() => setShowDisconnectModal(false)}
        disconnectIntegration={disconnectIntegration}
      />
      <AdjustQboSettingOverlay
        show={showAdjustQboSettingsOverlay}
        close={() => setShowAdjustQboSettingsOverlay(false)}
        refreshIntegrations={() => getIntegrationsSync(false, false)}
      />
      <ResumePaymentSyncModal
        show={showResumePaymentModal}
        close={() => setShowResumePaymentModal(false)}
        showAdjustQboSettingsOverlay={() => {
          setShowResumePaymentModal(false);
          setShowAdjustQboSettingsOverlay(true);
        }}
      />
    </CpCard>
  );
}

const css = k`
  .disabled-dropdown-item {
    color: var(--cp-color-app-inactive-text);
  }

  .disabled-dropdown-item:hover {
    cursor: default;
    background-color: transparent;
  }

  .qbo-card-error {
    color: var(--cp-color-app-warning-text);
    font-size: 1.2rem;
    font-style: italic;
    text-align: center;
    margin-right: 1.6rem;
    max-width: 50rem;
  }
`;

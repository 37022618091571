import React from "react";
import { k, useCss } from "kremling";
import {
  CpButton,
  CpOverlay,
  cdnImage,
  constants,
} from "canopy-styleguide!sofe";

import Instructions from "./instructions.component";

import type { IntegrationInfo } from "../../types/integration.d.ts";

type RestartInstructionsProps = {
  setShowSyncPreview: ({
    showSyncPreview,
  }: {
    showSyncPreview: boolean;
  }) => void;
  setShowServiceItemsPreview: () => void;
  hasInvoicePermissions: boolean;
  qboIntegrationInfo: IntegrationInfo;
};

export default function RestartInstructions({
  setShowSyncPreview,
  setShowServiceItemsPreview,
  hasInvoicePermissions,
  qboIntegrationInfo,
}: RestartInstructionsProps) {
  const scoped = useCss(css);

  return (
    <>
      <CpOverlay.Header title="Restart QuickBooks Sync">
        <div className="cp-flex-center">
          {hasInvoicePermissions && (
            <CpButton
              btnType="secondary"
              onClick={() => setShowSyncPreview({ showSyncPreview: false })}
            >
              Cancel
            </CpButton>
          )}
          <CpButton
            btnType="primary"
            onClick={setShowServiceItemsPreview}
            className="cp-ml-8"
            disabled={!hasInvoicePermissions}
          >
            Next
          </CpButton>
        </div>
      </CpOverlay.Header>
      <CpOverlay.Body>
        {hasInvoicePermissions ? (
          <div {...scoped} className="instructions">
            <div className="instructions-left">
              <img
                src={cdnImage("restart_sync.svg")}
                style={{ height: "40rem", marginTop: "-2.4rem" }}
                alt="Restart Sync illustration"
              />
            </div>
            <div className="instructions-right">
              <div className="instructions-header cp-wt-semibold">
                Restart your QBO sync
              </div>
              <div className="instruction cp-pt-8 cp-pb-16">
                The client sync is excluded from this restart. But you may
                restart the QuickBooks sync for service items, invoices,
                payments, credits, and deposits.
              </div>
              <div className="instruction">
                <strong>What to expect?</strong>
              </div>
              <ul>
                <li>
                  All previous links between Canopy & QBO items will be lost.
                  (excluding clients).
                </li>
                <li>
                  You will then go through the original set up process for each
                  area of the QBO sync (excluding clients).
                </li>
                <li>
                  Going forward, any newly created item, such as an invoice,
                  payment, etc. will also be created in QBO as long as it meets
                  all the syncing requirements.
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <Instructions
            imgSrc="need_permissions_graphic.svg"
            instructionsTitle="You don't currently have permissions to sync service items and invoices:"
            instructions={[
              "To gain permission, please contact your account manager to change your permissions.",
              "After your permissions have been changed, restart your sync process to try again.",
              `If you believe there is an issue, please contact Canopy support: ${constants.canopyPhone}.`,
            ]}
            isNumberedList
            integration={qboIntegrationInfo}
          />
        )}
      </CpOverlay.Body>
    </>
  );
}

const css = k`
    .instructions {
      display: flex;
      justify-content: center;
      margin: 14rem 2rem 0;
    }
  
    .instructions-right {
      margin-left: 5rem;
      width: 45rem;
    }
  
    .instructions-left {
      display: flex;
      align-items: center;
      min-width: 42rem;
    }
  
    .instructions-header {
      font-size: 3.2rem;
      padding: 0.8rem 0 0.8rem 0;
      max-width: 45rem;
      font-weight: 600;
    }
  
    .instruction {
      max-width: 55rem;
      font-size: 1.6rem;
      color: var(--cp-color-app-secondary-text);
    }
  
    .instructions li {
      font-size: 1.6rem;
      color: var(--cp-color-app-secondary-text);
      padding-top: 2.4rem;
    }
  `;

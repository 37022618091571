import { k } from "kremling";

export const commonCss = k`
  .section-instructions {
    padding: 2.4rem 1.6rem;
    display: flex;
    width: fit-content;
    background-color: var(--cp-color-well-l3-bg);
  }

  .instructions-description {
    max-width: 84rem;
    min-width: 55rem;
  }

  .instructions-steps {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3.2rem;
  }

  .instruction-step {
    width: 18.5rem;
    margin: 0.8rem 1.6rem;
  }

  .clients-display {
    overflow-x: auto;
  }

  .client-table {
    text-align: left;
    border-collapse: collapse;
    margin-top: 3.2rem;
    border: 0.1rem solid var(--cp-color-card-border);
    border-left: 0;
  }

  .client-table > tbody > tr > td, thead > tr > th {
    padding: 0.8rem 1.6rem;
    min-width: 30rem;
    max-width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    height: .1rem;
  }

  .small-col {
    min-width: 16rem !important;
    max-width: 16rem !important;
  }

  .name-initials {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .client-name {
    width: 18rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .client-name-cell {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.6rem;
    border-left: 0.1rem solid var(--cp-color-card-border);
    border-right: 0.1rem solid var(--cp-color-card-border);
  }

  .sticky-cell {
    position: sticky;
    left: 0;
    background: #fff;
    padding: 0 !important;
    background-clip: padding-box;
    border-bottom: 0.1rem solid var(--cp-color-card-border);
  }

  .border-top {
    border-top: 0.1rem solid var(--cp-color-card-border);
  }

  .logo-right {
    width: 5rem;
    text-align: center;
    padding-top: 0.4rem;
  }

  .large-col {
    min-width: 45rem;
    max-width: 45rem;
  }

  .large-col {
    min-width: 45rem !important;
    max-width: 45rem !important;
  }

  .help-article-button {
    margin-top: 8px;
    margin-left: -16px;
  }
`;

import React from "react";
import { CpButton, CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import { k, useCss } from "kremling";

import type { ActionsCellSectionProps } from "../types/integration.d.ts";
import { notifyAnalytics } from "../analytics.resource";

export default function ActionsCell({
  sectionObj,
  status,
}: {
  sectionObj: ActionsCellSectionProps;
  status: string;
}) {
  const scoped = useCss(css);
  let iconColor = "var(--cp-color-pill-button-hover-bg)";
  let statusText = "Not started";
  if (status === "paused") {
    iconColor = "#F2943F";
    statusText = "Paused";
  } else if (status === "syncing") {
    iconColor = "var(--cp-color-app-success-text)";
    statusText = "Synced";
  } else if (status === "error") {
    iconColor = "var(--cp-color-app-error-text)";
    statusText = "Error";
  }

  return (
    <div {...scoped} className="row-actions">
      <div>
        <CpIcon
          fill={iconColor}
          name="shape-circle-closed"
          className="cp-mr-8"
        />
        {statusText}
      </div>
      {!sectionObj.first_synced_at && (
        <CpButton
          className="cp-ml-32"
          btnType="flat"
          onClick={() => {
            notifyAnalytics(
              sectionObj.user,
              "qbo",
              "qbo",
              `qbo_${sectionObj.id}_sync_started`,
              {},
            );
            sectionObj.startSync(sectionObj.id);
          }}
          disabled={sectionObj.syncDisabled}
          showLoader={sectionObj.showLoader && !sectionObj.syncDisabled}
        >
          Start
        </CpButton>
      )}
      <div className="row-kabob">
        {sectionObj.hasPermissions &&
        sectionObj.id === "payments" &&
        sectionObj.first_synced_at ? (
          <CpDropdown
            position="bottom-end"
            contentWidth={240}
            renderTrigger={({ toggle }) => (
              <CpButton
                icon="misc-kabob"
                aria-label="integration-menu"
                onClick={toggle}
              />
            )}
            renderContent={() => (
              <div className="cp-select-list">
                {sectionObj.first_synced_at && sectionObj.synced_at && (
                  <button
                    onClick={() =>
                      sectionObj.updatePaymentsSync({ enabled: false })
                    }
                  >
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name="shape-rectangles"
                      fill="var(--cp-color-app-secondary-text)"
                    />
                    Pause payment integration
                  </button>
                )}
                {sectionObj.first_synced_at && !sectionObj.synced_at && (
                  <button
                    onClick={() =>
                      sectionObj.updatePaymentsSync({ enabled: true })
                    }
                  >
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name="shape-triangle-right-open"
                      fill="var(--cp-color-app-secondary-text)"
                    />
                    Resume payment integration
                  </button>
                )}
              </div>
            )}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const css = k`
    .row-actions {
        display: flex;
        align-items: center;
        position: relative;
    }

    .row-kabob {
        position: absolute;
        right: 0.4rem;
    }
`;

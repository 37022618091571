import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getIrsOrganizations() {
  return fetchAsObservable(`/api/transcripts/irs-tokens`).pipe(pluck("tokens"));
}

export const getIrsAuthUrl = () => {
  return fetchAsObservable("/api/transcripts/irs_url").pipe(pluck("url"));
};

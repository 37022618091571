import React from "react";
import { useCss, k } from "kremling";
import { CpOverlay, cdnImage } from "canopy-styleguide!sofe";

export default function SuccessPage() {
  const scope = useCss(css);

  const closeWindow = () => {
    return window.close();
  };

  return (
    <CpOverlay show onClose={closeWindow}>
      <CpOverlay.Header title="Integration Complete" />
      <CpOverlay.Body>
        <div {...scope} className="success-page-body">
          <img src={cdnImage("check.svg")} style={{ height: 100 }} />
          <h2>Successfully connected!</h2>
        </div>
      </CpOverlay.Body>
    </CpOverlay>
  );
}

const css = k`
  .success-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

import React from "react";
import { CpLoader, CpNameChip } from "canopy-styleguide!sofe";
import { QboIcon } from "../common/qbo-icon.component";

export function RowLoading({ newCrmEnabled }: { newCrmEnabled: boolean }) {
  return (
    <tr>
      <td>
        <CpNameChip initials="-" />
      </td>
      <td className="cp-text-center">
        <QboIcon />
      </td>
      {newCrmEnabled && (
        <td>
          <CpLoader />
        </td>
      )}
      <td>
        <CpLoader />
      </td>
      <td>
        <CpLoader />
      </td>
      <td>
        <CpLoader />
      </td>
      <td colSpan={2}>
        <CpLoader />
      </td>
    </tr>
  );
}
